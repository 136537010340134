import PageBase from './PageBase.js';
import Hero from  '../panels/Home/Hero/Hero.js'
import HomeSection1 from '../panels/Home/HomeSection1/HomeSection1.js';
import Pwa from  '../panels/Home/Pwa/Pwa.js'
import CookieConsentOptions from '../panels/Home/CookieConsent/CookieConsentOptions.js';

export default class HomePage extends PageBase {
    Title = 'Home';
    #SECTIONS = ["HeroSection", "PwaSection", "AndAnotherSection"];

    async Render() {
        await this.Init();
    }
    
    Init = async () => {
        let sectionsHtml = this.MakePageSectionsHtml(this.#SECTIONS);
        $('main#Content').html(sectionsHtml);
        await this.#populateSections();
        this.#configureClickHandlers();
    }
    
    // internals

    #configureClickHandlers ()  { // dont use arrow function def otherwise 'this' may not be valid
    }

    async #populateSections () {
        let uiHero = new Hero('#'+this.#SECTIONS[0]);
        await uiHero.Render();
        let panelSection1= new HomeSection1('#'+this.#SECTIONS[1]);
        await panelSection1.Render();
        let uiPwa = new Pwa('#'+this.#SECTIONS[2]);
        await uiPwa.Render();
        CookieConsent.run(CookieConsentOptions);
    }
}
