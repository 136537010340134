import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import Auth from '../../../../utils/Auth.js';

export default class Logout extends UiBase {
    #USER_ID = '_userId';
    #LOGOUT_BUTTON = '_logout';
    #LOGOUT_ALL_BUTTON = '_logoutAll';
    #successCallback = null;
    #REDIRECT_TO = '#home';

    constructor(where, whatJson) {
        super(where, whatJson, TemplateHtml);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    SetSuccessCallback(successCallback) {
        this.#successCallback = successCallback;
    }

    #configureEventHandlers() {
        let self = this;
        let jqButtonLogout = $('#' + this.Id + this.#LOGOUT_BUTTON);
        jqButtonLogout.off('click');
        jqButtonLogout.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#logout);
        });
        let jqButtonLogoutAll = $('#' + this.Id + this.#LOGOUT_ALL_BUTTON);
        jqButtonLogoutAll.off('click');
        jqButtonLogoutAll.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#logoutAll);
        });
    }

    #logout = async() => {
        let userId = $('#' + this.Id + this.#USER_ID).val();
        console.log('logout');
        let resultsJson = await Auth.Logout(userId);
        if ((resultsJson) && ('err' in resultsJson) && (resultsJson.err)) {
            console.warn('Logout error: ', resultsJson);
            this.ShowFlashMessage(resultsJson.err);
        } else if (this.#successCallback) {
            this.#successCallback(this.#REDIRECT_TO);
            //window.history.pushState({}, "", this.#REDIRECT_TO); 
            window.location.href = this.#REDIRECT_TO;  
            window.location.reload();
        }        
    }

    #logoutAll = async() => {
        let userId = $('#' + this.Id + this.#USER_ID).val();
        console.log('logoutAll');
        let resultsJson = await Auth.LogoutAll(userId);
        if ((resultsJson) && ('err' in resultsJson) && (resultsJson.err)) {
            console.warn('logoutAll error: ', resultsJson);
            this.ShowFlashMessage(resultsJson.err);
        } else if (this.#successCallback) {
            this.#successCallback(this.#REDIRECT_TO);
            //window.history.pushState({}, "", this.#REDIRECT_TO);
            window.location.href = this.#REDIRECT_TO; 
            window.location.reload();
        }
    }
}