import UiBase from '../../../UiBase.js';
import LocalDataConfig from "../../../../data/local/Config/LocalConfig.js";
import Template from './Template.html';

export default class ManageConfig extends UiBase {
    #INPUT_NAME = '_SettingName';
    #INPUT_VALUE = '_SettingValue';
    #BTN_LOAD = '_LoadButton';
    #BTN_SAVE = '_SaveButton';
    #BTN_GETALL = '_GetAllButton';

    constructor(where, whatJson,) {
        super(where, whatJson, Template);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        $('#' + this.Id + this.#BTN_LOAD).off('click');
        $('#' + this.Id + this.#BTN_LOAD).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#loadSetting);
        });
        $('#' + this.Id + this.#BTN_SAVE).off('click');
        $('#' + this.Id + this.#BTN_SAVE).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#saveSetting);
        });
        $('#' + this.Id + this.#BTN_GETALL).off('click');
        $('#' + this.Id + this.#BTN_GETALL).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#getAll);
        });
    }

    #loadSetting = async () => {
        let settingName = $('#' + this.Id + this.#INPUT_NAME).val();
        console.log('Load setting: ' + settingName);
        let result = await LocalDataConfig.LoadData(settingName);
        console.log('Setting Value: ', result);
    }

    #saveSetting = async () => {
        let settingName = $('#' + this.Id + this.#INPUT_NAME).val();
        let settingVal = $('#' + this.Id + this.#INPUT_VALUE).val();
        console.log('Save setting: ' + settingName + ' = ' + settingVal);
    }

    #getAll = async () => {
        let settingName = $('#' + this.Id + this.#INPUT_NAME).val();
        console.log('Load setting: ' + settingName);
        let results = await LocalDataConfig.LoadAll();
        console.log('Setting Value: ', results);
    }

}