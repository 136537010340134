import Overlay from '../Overlay/Overlay.js';

export default class RecordOverlay extends Overlay {
    constructor(whereSelectorString, whatJson, optionalHeight) {
        super(whereSelectorString, whatJson, optionalHeight);
    }

    ShowAddRecordOverlay(parentUiObject, dbDataTableHandler, columnNamesMap) {
        let overlayJson = this.#buildAddHtml(this.Id, dbDataTableHandler, columnNamesMap);
        this.Render(overlayJson.title, overlayJson.body, overlayJson.buttons, parentUiObject);
        this.#updateEventHandlers();
    }

    ShowEditRecordOverlay(parentUiObject, dbDataTableHandler, columnNamesMap, selectedDataById) {
        let overlayJson = this.#buildEditHtml(this.Id, dbDataTableHandler, columnNamesMap, selectedDataById);
        this.Render(overlayJson.title, overlayJson.body, overlayJson.buttons, parentUiObject);
        this.#updateEventHandlers();
    }

    ShowDeleteRecordOverlay(parentUiObject, dbDataTableHandler,columnNamesMap, selectedDataById) {
        let overlayJson = this.#buildDeleteHtml(this.Id, dbDataTableHandler, columnNamesMap, selectedDataById);
        this.Render(overlayJson.title, overlayJson.body, overlayJson.buttons, parentUiObject);
        this.#updateEventHandlers();
    }

    async UpdateForeignFieldFromId(fieldName, selectedId) {
        console.log(fieldName + ': Selected ID: ' + selectedId);
        let jqInput = $('#' + this.Id).find('[data-column="' + fieldName + '"]');
        if (jqInput.length==1) {
            console.warn('This needs more work. Foreign fields need mapping to names and the jqInput might not be a HTML <input>');
            jqInput.val(selectedId);
        }
    }

    #updateEventHandlers() {
        console.log('RecordOverlay.#updateEventHandlers() - Is this required?');
    }

    #buildAddHtml(parentId, dbDataHandler, columnsMap) {
        let title = 'Add Record';
        let htmlBody = '<table><tbody>';
        let internalColNames = Object.keys(columnsMap);
        for (let j = 0; j < internalColNames.length; j++) {
            let colName = internalColNames[j];
            htmlBody += this.#htmlTableRowFieldInput(parentId, dbDataHandler, columnsMap, colName);
        }
        htmlBody += '</tbody>';
        htmlBody += '</table>';
        let buttons = '<button class="clickableElement" data-action="add">Add</button>';
        return {"title": title, "body": htmlBody, "buttons": buttons};
    }

    #buildEditHtml(parentId, dbDataHandler, columnsMap, selectedData) {
        let selectedIds = Object.keys(selectedData);
        if (selectedIds.length > 0) {
            let recJson = selectedData[selectedIds[0]];
            let title = 'Edit Record';
            let htmlBody = '<table><tbody>';
            let internalColNames = Object.keys(columnsMap);
            for (let j = 0; j < internalColNames.length; j++) {
                let colName = internalColNames[j];
                htmlBody += this.#htmlTableRowFieldInput(parentId, dbDataHandler, columnsMap, colName, recJson[colName]);
            }
            htmlBody += '</tbody>';
            htmlBody += '</table>';
            let buttons = '<button class="clickableElement" data-action="update" data-value="' + recJson.id + '">Save</button>';
            return {"title": title, "body": htmlBody, "buttons": buttons};
        }
    }

    #buildDeleteHtml(parentId, dbDataHandler, columnsMap, selectedData) {
        let selectedIds = Object.keys(selectedData);
        if (selectedIds.length > 0) {
            const DISABLED_INPUT_FIELD = true;
            let recJson = selectedData[selectedIds[0]];
            let title = 'Delete Record';
            let htmlBody = '<table><tbody>';
            let internalColNames = Object.keys(columnsMap);
            for (let j = 0; j < internalColNames.length; j++) {
                let colName = internalColNames[j];
                htmlBody += this.#htmlTableRowFieldInput(parentId, dbDataHandler, columnsMap, colName, recJson[colName], DISABLED_INPUT_FIELD);
            }
            htmlBody += '</tbody>';
            htmlBody += '</table>';
            let buttons = '<button class="clickableElement" data-action="delete" data-value="' + recJson.id + '">Delete</button>';
            return {"title": title, "body": htmlBody, "buttons": buttons};
        }
    }

    #htmlTableRowFieldInput(parentId, dbDataHandler, columnsMap, colName, fieldValue, isDisabled) {
        let html = '';
        fieldValue = fieldValue || '';
        isDisabled = isDisabled || false;
        if ((colName == 'id') || (colName == 'updatedAt')) isDisabled = true;

        if (!dbDataHandler.IsFieldObscured(colName)) {
            let disabled = '';
            if (isDisabled) disabled = ' disabled';
            let requiredHtml = '';
            let isRequired = dbDataHandler.IsRequired(colName);
            if (isRequired) {
                console.log('IsRequired ' + colName + ': ' + isRequired );
                requiredHtml = '* ';
            }

            html = '<tr>';
            html += '<td style="text-align: right;">' + requiredHtml + columnsMap[colName] + ': </td>';

            html += '<td style="text-align: left;">';
            if (dbDataHandler.IsFieldBoolean(colName)) {
                if (!fieldValue) fieldValue = dbDataHandler.FieldDefaultValue(colName) || false;
                let trueChecked = '';
                let falseChecked = '';
                if (fieldValue) trueChecked = 'checked';
                else falseChecked = 'checked';
                html += '<span>';
                html += '<label for="' + parentId + '_' + colName + '_true">True</label>';
                html += '<input type="radio" id="' + parentId + '_' + colName + '_true" name="' + parentId + '_' + colName + '" data-column="' + colName + '" value="True" ' + disabled + ' ' + trueChecked +'>';
                html += '<label for="' + parentId + '_' + colName + '_false">False</label>';
                html += '<input type="radio" id="' + parentId + '_' + colName + '_false" name="' + parentId + '_' + colName + '" data-column="' + colName + '" value="False" ' + disabled + ' ' + falseChecked +'>';
                html += '</span>';
                html += '</td><td>';

            } else if (dbDataHandler.IsFieldDate(colName)) {
                fieldValue = fieldValue.replace('Z', ''); // datetime-local requires an ISO date without the Z at the end
                html += '<input type=datetime-local data-column="' + colName + '" value="' + fieldValue + '" ' + disabled + '></td><td>';
            } else if (dbDataHandler.IsMappedField(colName)) {
                let mappingsForField = dbDataHandler.GetAllMappingsForField(colName);
                let keys = Object.keys(mappingsForField);
                html += '<select data-column="' + colName + '" ' + disabled + '>';
                keys.forEach((key) => {
                    let htmlSelected = '';
                    if (key + '' == fieldValue + '') htmlSelected = 'selected';
                    html += '<option value="' + key + '" ' + htmlSelected + '>' + mappingsForField[key] + '</option>'
                });
                html += '</select></td><td>';
            } else if (dbDataHandler.IsForeignKey(colName)) {
                html += '<input type=text data-column="' + colName + '" value="' + fieldValue + '" ' + disabled + '></td><td>';
                if (!isDisabled) html += '<button class="clickableElement" data-action="choose" data-value="' + colName + '">Choose...</button>';
            } else if (dbDataHandler.IsFieldText(colName)) {
                html += '<textarea rows=8 cols=30 type=text data-column="' + colName + '" ' + disabled + '>' + fieldValue + '</textarea></td><td>';
            } else {
                html += '<input type=text data-column="' + colName + '" value="' + fieldValue + '" ' + disabled + '></td><td>';
            }

            html += '</td>';
            html += '</tr>';
        }
        return html;
    }

}