import Group from '../../../data/api/Group.js';
import AdminPanelBase from './Shared/AdminPanelBase.js';

export default class GroupAdmin extends AdminPanelBase {
    
    constructor(where, whatJson) {
        let buttons = '';
        whatJson.sectionTitle = Group.GetTableSummary().displayNamePlural;
        whatJson.sectionDescription = Group.GetTableSummary().description;
        super(where, whatJson, Group, buttons);
    }

}