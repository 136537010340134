import UiBase from "../../UiBase";
import Template from './Template.html';
import CloseButton from './CloseButton.html';

export default class Overlay extends UiBase {
    #TITLE_DIV_SUFFIX = '_OverlayTitle';
    #CONTENT_DIV_SUFFIX = '_OverlayContent';
    #CONTROLS_DIV_SUFFIX = '_OverlayControls';
    #BUTTONS_CLASS = '.clickableElement';
    CallerInstance = null; 
    Height = 1; // valid options are 1, 2 or 3 for z-index 1000, 2000, 3000

    //constructor(whereSelectorString, whatJson, columnsNamesMap, valuesToNamesMap) { // todo remove arg 3 and 4
    constructor(whereSelectorString, whatJson, optionalHeight) { 
        optionalHeight = optionalHeight || 1;
        whereSelectorString = whereSelectorString || '#FixedOverlayContainer'+optionalHeight;
        super(whereSelectorString, whatJson, Template);
        this.Height = optionalHeight;
    }

    Render(title, body, buttons, optionalCallerInstance) {
        super.Render();
        $('#' + this.Id + this.#TITLE_DIV_SUFFIX).html(title);
        $('#' + this.Id + this.#CONTENT_DIV_SUFFIX).html('<div class="flexColStartCenter">' + body + '</div>');
        buttons = buttons || '';
        buttons += CloseButton
        $('#' + this.Id + this.#CONTROLS_DIV_SUFFIX).html(buttons); // default is just a cancel/close button
        $('#' + this.OVERLAY_ID).fadeIn();
        this.CallerInstance = optionalCallerInstance;
        this.#configureEventHandlers();
    }

    GetJqInputsByClass(className) {
        let jqInputs = $('#' + this.Id).find('input.' + className);
        return jqInputs;
    }

    GetAllInputs() {
        let resultColsValues = {};
        let jqInputs = $('#' + this.Id).find('input');
        let jqSelects = $('#' + this.Id).find('select');
        let jqTextAreas = $('#' + this.Id).find('textarea');
        let i=0;
        for (i=0;i<jqInputs.length;i++) {
            let jqInput = $(jqInputs[i]);
            let colName = jqInput.data('column');
            if (!colName) {
                console.warn('Input with no data-column attribute:' , jqInput);
            } else {
                let inputValue = jqInput.val();
                if ((inputValue) && (inputValue.length>0)) {
                    resultColsValues[colName] = inputValue;
                    if (jqInput.attr('type') == "radio") {
                        console.log('radio', jqInput.attr("checked"), jqInput);
                    }
                }
            }
        }
        for (i=0;i<jqSelects.length;i++) {
            let jqSelect = $(jqSelects[i]);
            let colName = jqSelect.data('column');
            if (!colName) {
                console.warn('Select with no data-column attribute:' , jqSelect);
            } else {
                let selectValue = jqSelect.find(":selected").val();
                if ((selectValue) && (selectValue.length>0)) resultColsValues[colName] = selectValue;
            }
        }
        for (i=0;i<jqTextAreas.length;i++) {
            let jqTA = $(jqTextAreas[i]);
            let colName = jqTA.data('column');
            if (!colName) {
                console.warn('TextArea with no data-column attribute:' , jqTA);
            } else {
                let taValue = jqTA.html();
                if ((taValue) && (taValue.length>0)) resultColsValues[colName] = taValue;
            }
        }
        return resultColsValues;
    }

    #configureEventHandlers() {
        let self = this;
        let jqButtons = $('#' + this.Id).find(this.#BUTTONS_CLASS);
        jqButtons.off('click');
        jqButtons.on('click', async function(e) {
            let jqButton = $(this);
            let action = jqButton.data('action');
            if (action == 'Close') {
                self.Hide();
            } else if ((self.CallerInstance) && ('OverlayButtonClick' in self.CallerInstance)) {
                await self.CallerInstance.OverlayButtonClick(jqButton, self);
            } else {
                console.log('Overlay error: No handler for custom button click');
            }
        });
    }

}