export default {

    "Config": {
        "LocalTableName": "Config",
        "LocalFieldNames": {
            "LocalId": "id",
            "RemoteId": "rid",
            "Name": "Name",
            "Value": "Value",
            "LastUpdated": "LastUpdatedDt",
            "IsDeleted": "IsDeleted"
        },
        "LocalIndices": [
            "lid",
            "id",
            "Name",
            "LastUpdatedDt"
        ],
        "LocalAutoIncrement": true
    },

/*
    "Items": {
        "LocalTableName": "Items",
        "LocalFieldNames": {
            "LocalId": "id",
            "RemoteId": "rid",
            "LocalSetId": "SetLid",
            "RemoteSetId": "SetId",
            "Tags": "Tags",
            "Latitude": "Latitude",
            "Longitude": "Longitude",
            "ValueInt": "Value",
            "ValueFloat": "Value",
            "ValueText": "Value",
            "Notes": "Notes",
            "Litres": "Litres",
            "Mileage": "Mileage",
            "Json": "Json",
            "When": "DT",
            "LastUpdated": "LastUpdatedDt", 
            "UserId": "UserLinkId", 
            "IsDeleted": "IsDeleted"
        }, "LocalIndices":
            ["lid", "id", "SetLid", "SetId", "DT", "LastUpdatedDt"],
        "LocalAutoIncrement": true,
       
        "CompoundIndexes": [
            { "indexName": "SetLid, DT", "fieldsToIndex": ["SetLid", "DT"] }]
    }*/
};