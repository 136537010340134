import JourneyBase from "../JourneyBase.js";

export default class ShowAccountPage extends JourneyBase {
    static Name = 'ShowAccountPage';
    static Description = 'My Account';
    static Icon = '<i class="fa-solid fa-user-gear"></i>';

    static async Start() {
        let pageTarget = '/#myaccount';
        window.location.href = pageTarget;
    }
}