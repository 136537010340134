import UiBase from '../../UiBase.js';
import Template from './Template.html';
import HtmlChooser from './HtmlChooser.html';
import HtmlTableBuilder from './HtmlTableBuilder.js';
//import HtmlRecordOverlayBuilder from './HtmlRecordOverlayBuilder.js';
import RecordOverlay from './RecordOverlay.js';
import Overlay from '../Overlay/Overlay.js';
import DbTables from '../../../data/api/allDbTables.js'
import { result } from 'lodash';

export default class DataTable extends UiBase {
    #STD_BTNS_CONTAINER_CLASS = 'stdControls';
    #CUSTOM_BTNS_CONTAINER_CLASS = 'customControls';
    #TABLE_CONTAINER = '_dataTable';
    #HTML_CHOOSER_DIV_ID = '#HtmlChooserTable';
    #columnNamesMap = {};
    #currentRowsById = {};
    #parentUiObject = null;
    #dbTableName = null;
    #isMultiSelectEnabled = true;
    #queryMetadata = { // captured from API query results on RenderTable
        "count": 0, // from API
        "offset": 1, // from API
        "limit": 10, // from API
        "isFirstPage": false, // derived from API
        "isLastPage": false, // derived from API
        "searchTerm": "" // not currently used - placeholder for when search is implemented
    };
    // for chooser overlays...
    #fieldChooserOverlay = null;
    #chooserDataTable = null;

    constructor(whereSelectorString, whatJson, parentUiObject, dbTableName) {
        super(whereSelectorString, whatJson, Template);
        this.#dbTableName = dbTableName;
        this.#parentUiObject = parentUiObject;
        if (dbTableName in DbTables) {
            this.#columnNamesMap = DbTables[dbTableName].GetTableColumnDisplayNames();
        } else {
            console.warn('------------------------------------------------------');
            console.warn('ui/misc/DataTable - Missing ' + dbTableName + ' in ', DbTables);
            console.warn('------------------------------------------------------');
        }
    }

    RenderTable = async (apiQueryResultsJson) => {
        let dataRows = apiQueryResultsJson.rows;
        this.#cacheApiResponseMetadata(apiQueryResultsJson);
        let htmlTable = '<br /><div class="warn">No data</div>';
        if (dataRows.length != 0) {
            htmlTable = await HtmlTableBuilder.Build(this.#dbDataTableHandler(), this.#columnNamesMap, dataRows);
            this.#currentRowsById = this.#cacheRowsById(dataRows);
        }
        $('#' + this.Id + this.#TABLE_CONTAINER).html(htmlTable);
        this.#updateButtonVisibility();
    }

    SelectedIds() {
        return this.#selectedIds();
    }

    SelectedDataById() {
        console.log('REMOVE THIS METHOD IF IT IS NO LONGER CALLED - DataTable.SelectedDataById');
        return this.#selectedDataById();
    }

    async OverlayButtonClick(jqButton, overlay) {
        let action = jqButton.data('action');
        if (action) {
            if (action == 'chooserOK') { // return from foreign field chooser
                let selectedIds = this.#chooserDataTable.SelectedIds();
                if (selectedIds.length = 1) {
                    let fieldName = jqButton.data('column');
                    if (fieldName) await this.#parentUiObject.Overlay().UpdateForeignFieldFromId(fieldName, selectedIds[0]);
                }
                this.#fieldChooserOverlay.Hide();
            } else { // other overlay action such as Add/Update/Delete record
                let hasTableChanged = await this.#handleOverlayButtonClick(jqButton, overlay, action);
                if (hasTableChanged) {
                    await this.#parentUiObject.RefreshTable();
                }
            }
        }
    }

    DisableMultiSelect() {
        this.#isMultiSelectEnabled = false;
    }

    // Public methods for use when data table is inside an overlay

    HideAddEditDeleteButtons() {
        let jqStdButtons = $('#' + this.Id).find('.' + this.#STD_BTNS_CONTAINER_CLASS + '>button');
        for (let i = 0; i < jqStdButtons.length; i++) {
            let jqButton = $(jqStdButtons[i]);
            let btnAction = jqButton.data('action');
            if ((btnAction == 'add') || (btnAction == 'edit') || (btnAction == 'delete')) {
                jqButton.hide();
            }
        }
    }

    // internals

    #dbDataTableHandler() {
        return DbTables[this.#dbTableName];
    }

    #cacheApiResponseMetadata(apiQueryResultsJson) {
        this.#queryMetadata.count = apiQueryResultsJson.count;
        this.#queryMetadata.limit = apiQueryResultsJson.pagination.limit;
        this.#queryMetadata.offset = apiQueryResultsJson.pagination.offset;
        if (this.#queryMetadata.offset == 0) this.#queryMetadata.isFirstPage = true;
        else this.#queryMetadata.isFirstPage = false;
        let lastRecNumber = this.#queryMetadata.limit + this.#queryMetadata.offset;
        if (lastRecNumber >= this.#queryMetadata.count) this.#queryMetadata.isLastPage = true;
        else this.#queryMetadata.isLastPage = false;
    }

    #selectedDataById() {
        let resultsById = {};
        let selectedIds = this.#selectedIds();
        selectedIds.forEach((id) => {
            let rec = this.#currentRowsById[id];
            if (rec) resultsById[id] = rec;
        });
        return resultsById;
    }

    #selectedIds() {
        let idsArray = [];
        let jqSelectedRows = $('#' + this.Id).find('.selected');
        for (let i = 0; i < jqSelectedRows.length; i++) {
            let jqRowEl = $(jqSelectedRows[i]);
            let id = jqRowEl.data('id');
            if (id) idsArray.push(id);
        }
        return idsArray;
    }

    #cacheRowsById(dataRows) {
        let rowsCacheById = {};
        let count = 0;
        for (let i = 0; i < dataRows.length; i++) {
            let rec = dataRows[i];
            let uniqueID = count;
            if ('id' in rec) uniqueID = rec.id;
            rowsCacheById[uniqueID] = rec; // cache data rows by actual or made up id
            count++;
        }
        return rowsCacheById;
    }

    #configureClickHandlers() {
        let self = this;
        let jqRows = $('#' + this.Id + this.#TABLE_CONTAINER + '>table>tbody>tr');
        jqRows.off('click');
        jqRows.on('click', function (e) {
            let jqRowClicked = $(this);
            self.#handleRowClick(jqRowClicked);
        });
        let jqStdButtons = $('#' + this.Id).find('.' + this.#STD_BTNS_CONTAINER_CLASS + '>button');
        jqStdButtons.off('click');
        jqStdButtons.on('click', async function (evt) {
            let jqButtonClicked = $(this);
            await self.#handleStandardButtonClick(jqButtonClicked);
        });
        let jqCustomButtons = $('#' + this.Id).find('.' + this.#CUSTOM_BTNS_CONTAINER_CLASS + '>button');
        jqCustomButtons.off('click');
        jqCustomButtons.on('click', async function (evt) {
            if (self.#parentUiObject) {
                if ('HandleClick' in self.#parentUiObject) {
                    let jqButtonClicked = $(this);
                    await self.#parentUiObject.HandleClick(jqButtonClicked, self.#selectedDataById());
                } else {
                    console.warn('Missing HandleClick on ', self.#parentUiObject);
                }
            }
        });
    }

    #handleRowClick(jqRow) {
        if (!this.#isMultiSelectEnabled) {
            let jqRows = $('#' + this.Id + this.#TABLE_CONTAINER + '>table>tbody>tr');
            jqRows.removeClass('selected');
            jqRow.addClass('selected');

        } else {
            jqRow.toggleClass('selected');
        }
        this.#updateButtonVisibility();
    }

    async #handleStandardButtonClick(jqButton) {
        let action = jqButton.data('action');
        switch (action) {
            case 'previous':
                if ((this.#parentUiObject) && ('PreviousPage' in this.#parentUiObject)) this.#parentUiObject.PreviousPage(jqButton);
                else console.warn('DataTable UI: Cannot call parentUiObject.PreviousPage()', this.#parentUiObject);
                break;
            case 'next':
                if ((this.#parentUiObject) && ('NextPage' in this.#parentUiObject)) this.#parentUiObject.NextPage(jqButton);
                else console.warn('DataTable UI: Cannot call parentUiObject.NextPage()', this.#parentUiObject);
                break;
            case 'add':
                this.#showAddRecordOverlay();
                break;
            case 'edit':
                this.#showEditRecordOverlay();
                break;
            case 'delete':
                this.#showDeleteRecordOverlay();
                break;
            case 'search':
                this.#doSearch();
                break;
            default:
        }
        this.#updateButtonVisibility();
    }

    #updateButtonVisibility() {
        let selectedIds = this.#selectedIds();
        let jqPreviousButton = $('#' + this.Id).find('.' + this.#STD_BTNS_CONTAINER_CLASS + '>button[data-action="previous"]');
        if (this.#queryMetadata.isFirstPage) jqPreviousButton.prop("disabled", true);
        else jqPreviousButton.prop("disabled", false);
        let jqNextButton = $('#' + this.Id).find('.' + this.#STD_BTNS_CONTAINER_CLASS + '>button[data-action="next"]');
        if (this.#queryMetadata.isLastPage) jqNextButton.prop("disabled", true);
        else jqNextButton.prop("disabled", false);
        let jqEditButton = $('#' + this.Id).find('.' + this.#STD_BTNS_CONTAINER_CLASS + '>button[data-action="edit"]');
        let jqDeleteButton = $('#' + this.Id).find('.' + this.#STD_BTNS_CONTAINER_CLASS + '>button[data-action="delete"]');
        let jqCustomButtons = $('#' + this.Id).find('.' + this.#CUSTOM_BTNS_CONTAINER_CLASS + '>button');
        if (selectedIds.length == 0) {
            jqEditButton.prop("disabled", true);
            jqDeleteButton.prop("disabled", true);
            jqCustomButtons.prop("disabled", true);
        } else {
            jqEditButton.prop("disabled", false);
            jqDeleteButton.prop("disabled", false);
            jqCustomButtons.prop("disabled", false);
        }
        this.#configureClickHandlers();
    }

    #showAddRecordOverlay() {
        let recOverlay = new RecordOverlay();
        recOverlay.ShowAddRecordOverlay(this, this.#dbDataTableHandler(), this.#columnNamesMap);
        this.#parentUiObject.Overlay(recOverlay);
        //let overlayJson = HtmlRecordOverlayBuilder.BuildAdd(this.Id, this.#dbDataTableHandler(), this.#columnNamesMap);
        //this.#parentUiObject.Overlay().Render(overlayJson.title, overlayJson.body, overlayJson.buttons, this);
    }

    #showEditRecordOverlay() {
        let recOverlay = new RecordOverlay();
        recOverlay.ShowEditRecordOverlay(this, this.#dbDataTableHandler(), this.#columnNamesMap, this.#selectedDataById());
        this.#parentUiObject.Overlay(recOverlay);
        //let overlayJson = HtmlRecordOverlayBuilder.BuildEdit(this.Id, this.#dbDataTableHandler(), this.#columnNamesMap, this.#selectedDataById());
        //this.#parentUiObject.Overlay().Render(overlayJson.title, overlayJson.body, overlayJson.buttons, this);
    }

    #showDeleteRecordOverlay() {        
        let recOverlay = new RecordOverlay();
        recOverlay.ShowDeleteRecordOverlay(this, this.#dbDataTableHandler(), this.#columnNamesMap, this.#selectedDataById());
        this.#parentUiObject.Overlay(recOverlay);
        //let overlayJson = HtmlRecordOverlayBuilder.BuildDelete(this.Id, this.#dbDataTableHandler(), this.#columnNamesMap, this.#selectedDataById());
        //this.#parentUiObject.Overlay().Render(overlayJson.title, overlayJson.body, overlayJson.buttons, this);
    }

    #doSearch() {
        console.warn('TODO ui DataTable.#doSearch()');
    }

    async #handleOverlayButtonClick(jqButton, overlay, action) {
        let hasTableChanged = false;
        let resultJson = {"err": null, "data" : null};
        let userData = null;
        switch (action) {
            case 'add':
                userData = overlay.GetAllInputs();
                console.log('userAddData: ', userData);
                resultJson = await this.#dbDataTableHandler().AddRecord(userData);
                this.#parentUiObject.Overlay().Hide();
                break;
            case 'update':
                userData = overlay.GetAllInputs();
                console.log('userUpdateData: ', userData);
                resultJson = await this.#dbDataTableHandler().UpdateRecord(userData);
                this.#parentUiObject.Overlay().Hide();
                break;
            case 'delete':
                userData = overlay.GetAllInputs();
                console.log('userDeleteData: ', userData);
                resultJson = await this.#dbDataTableHandler().DeleteRecord(userData);
                this.#parentUiObject.Overlay().Hide();
                break;
            case 'choose':
                let fieldName = jqButton.data('value');
                await this.#showFieldChooser(fieldName);
                break;
            default:
                console.log('Unknown action: ' + action);
        }
        
        if (resultJson.err) {
            console.warn('Error: ',resultJson.err);
        } else if (resultJson.data) {
            hasTableChanged = true;
        }
        return hasTableChanged;
    }

    // this is a kind of recursion, the current DataTable instatiates a field chooser and then we render a new DataTable into the chooser!
    async #showFieldChooser(fieldName) {
        console.log('TODO - DataTable.OverlayButtonClick() choose: ', fieldName);
        const Z_INDEX  =2;
        if (!this.#fieldChooserOverlay) this.#fieldChooserOverlay = new Overlay(null, null, Z_INDEX);
        let tableName = this.#dbDataTableHandler().ForeignTableName(fieldName);
        if (tableName) {
            let responseJson = await DbTables[tableName].Get();
            console.log(responseJson);
            let buttons = '<button class="clickableElement" data-action="chooserOK" data-column="' + fieldName + '">OK</button>';
            this.#fieldChooserOverlay.Render('Select ' + tableName, HtmlChooser, buttons, this);
            let chooserDtWhatJson = {
                "sectionTitle": 'Select your field',
                "sectionDescription": "",
                "buttonsHtml": ""
            }
            this.#chooserDataTable = new DataTable(this.#HTML_CHOOSER_DIV_ID, chooserDtWhatJson, this, tableName);
            this.#chooserDataTable.Render();
            this.#chooserDataTable.HideAddEditDeleteButtons();
            this.#chooserDataTable.DisableMultiSelect();
            this.#chooserDataTable.RenderTable(responseJson.data);
        }
    }

}