import Users from '../../../data/api/Users/User.js';
import AdminPanelBase from './Shared/AdminPanelBase.js';

export default class UserAccounts extends AdminPanelBase {

    constructor(where, whatJson) {
        let buttons = '<button>Reset password</button><button>Verify email</button><button>Remove email verification</button><button>Disable user</button>';
        whatJson.sectionTitle = Users.GetTableSummary().displayNamePlural;
        whatJson.sectionDescription = Users.GetTableSummary().description;
        super(where, whatJson, Users, buttons);
        this.SetSubClass(this);
    }

    async HandleClick (jqButton, selectedDataById)  {
        let buttonText = jqButton.html().toLowerCase();
        let selectedIds = Object.keys(selectedDataById);
        let statusMsgsArray = [];
        let hasTableChanged = false;
        let i = 0, id=0, apiResultJson = null;
        let title = '', body='', buttons='';
        switch (buttonText) {
            case 'verify email':
                //selectedIds.forEach(async (id) => {
                for (i=0;i<selectedIds.length;i++) {
                    id = selectedIds[i];
                    apiResultJson = await Users.ForceEmailVerification(id);
                    if (apiResultJson.err) {
                        statusMsgsArray.push(apiResultJson.err);
                    } else {
                        statusMsgsArray.push(apiResultJson.data);
                        hasTableChanged = true;
                    }
                };
                break;
            case 'remove email verification':
                for (i=0;i<selectedIds.length;i++) {
                    id = selectedIds[i];
                    apiResultJson = await Users.ForceEmailUnverification(id);
                    if (apiResultJson.err) {
                        statusMsgsArray.push(apiResultJson.err);
                    } else {
                        statusMsgsArray.push(apiResultJson.data);
                        hasTableChanged = true;
                    }
                };
                break;
            case 'reset password':
                if (selectedIds.length != 0) {
                    //console.log('this: ', this);
                    title = 'Reset Password';
                    let firstId = selectedIds[0];
                    let rec = selectedDataById[firstId];
                    let email = rec.email || 'email unknown'
                    body = '<div>Selected user ' + email + '</div>';
                    body += '<div><label>New password</label><input class="pwd" type=password placeholder="Enter new password" /></div>';
                    body += '<div><label>Confirm password</label><input class="pwd2" type=password placeholder="Confirm new password" /></div>';
                    buttons = '<button class="clickableElement" data-value="' + firstId + '">Update password</button>';
                    //console.log('reset password overlay')
                    this.Overlay().Render(title, body, buttons, this);
                }
                break;
            case 'disable user':
                console.log('todo: UserAccounts - disable user')
                break;
            default:
                console.warn('Unhandled click in UserAccounts: ' + buttonText);
        }
        console.log('hasTableChanged: ', hasTableChanged);
        if (hasTableChanged) {
            title = 'Success';
            statusMsgsArray.forEach( (msg) => {
                body += '<div>' + msg + '</div>';
            });
            this.Overlay().Render(title, body, buttons, this);
            await this.RefreshTable(selectedIds);
        }
    }

    async OverlayButtonClick(jqButton) {
        //console.log('overlay button click...');
        let buttonText = jqButton.html().toLowerCase();
        let userId = jqButton.data('value');
        let jqPwdInput = this.Overlay().GetJqInputsByClass('pwd');
        let jqConfirmPwdInput = this.Overlay().GetJqInputsByClass('pwd2');
        if ( (jqPwdInput.length == 1) && (jqConfirmPwdInput.length == 1) ) {
            let pwd = jqPwdInput.val();
            let pwd2 = jqConfirmPwdInput.val();
            if (pwd != pwd2) {
                alert('Passwords must match');
            } else {
                // apply password reset here resetPasswordForce
                let resultJson = await Users.ForcePwdReset(userId, pwd);
                if (resultJson.err) alert(resultJson.err);
                else this.Overlay().Hide();
            }
        }

    }



}