import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import Auth from '../../../../utils/Auth.js';

export default class Register extends UiBase {
    #BTN_REGISTER = 'AuthRegister';
    #INPUT_REGISTER_EMAIL = 'AuthRegisterEmail';
    #INPUT_REGISTER_PWD = 'AuthRegisterPassword';
    #INPUT_REGISTER_PWD2 = 'AuthRegisterConfirmPassword';
    #successCallback = null;

    constructor(where, whatJson) {
        where = where || 'main#Content';
        super(where, whatJson, TemplateHtml);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    SetSuccessCallback(successCallback) {
        this.#successCallback = successCallback;
    }

    #configureEventHandlers() {
        let self = this;
        $('#' + this.#BTN_REGISTER).off('click');
        $('#' + this.#BTN_REGISTER).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#register);
        });
        $('#' + this.#INPUT_REGISTER_PWD2).off('keyup');
        $('#' + this.#INPUT_REGISTER_PWD2).on('keyup', function (evt) { // auto click Register button when pressing enter on password
            if (evt.which == 13) $('#' + self.#BTN_REGISTER).trigger('click');
        });
    }

    #register = async () => {
        let email = $('#' + this.#INPUT_REGISTER_EMAIL).val();
        let pwd = $('#' + this.#INPUT_REGISTER_PWD).val();
        let pwd2 = $('#' + this.#INPUT_REGISTER_PWD2).val();
        let registerJson = await Auth.Register(email, pwd, pwd2);
        if (!registerJson.err) {
            console.log('RegisterJson: ', registerJson);
            let whoJson = await Auth.WhoAmI();
            console.log('Who: ', whoJson);
            if (this.#successCallback) this.#successCallback();
        } else {
            this.ShowFlashMessage('Registration failed');
            console.log(registerJson);
        }
    }

}