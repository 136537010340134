import UiBase from '../../../UiBase.js';
import TemplateHtml from '../Shared/Template.html';
//import { FieldLoading } from '../../../../wcs/FieldLoading/FieldLoading.js';
import DataTable from '../../../misc/DataTable/DataTable.js';
import Overlay from '../../../misc/Overlay/Overlay.js';

export default class AdminPanelBase extends UiBase {
    #LOAD_BTN = '_loadButton';
    #DATA_TABLE_DIV_ID = '_listContainer';
    #dbTableHandler = null;
    #subClass = null;
    #uiOverlay = null;
    DataTableInstance = null;
    CurrentDataPage = 1;
    
    constructor(where, whatJson, dbTableHandler, extraButtonsHtml) {
        super(where, whatJson, TemplateHtml);
        this.#dbTableHandler = dbTableHandler;
        let dataTableTarget = '#' + this.Id + this.#DATA_TABLE_DIV_ID;
        let dataTableWhatJson = {buttonsHtml: extraButtonsHtml || ''};
        this.DataTableInstance = new DataTable(dataTableTarget, dataTableWhatJson, this, dbTableHandler.JSON_TABLE_NAME);
    }

    SetSubClass(subClass) {
        this.#subClass = subClass;
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    async RefreshTable() {
        await this.#loadData();
    }

    DbTableDataHandler() {
        return this.#dbTableHandler;
    }

    PreviousPage = async(jqButton) => {
        if (this.CurrentDataPage!=1) this.CurrentDataPage--;
        await this.ProcessButtonAsyncAction(jqButton, this.#loadData);
        this.#configureEventHandlers();
    }

    NextPage = async(jqButton) => {
        this.CurrentDataPage++;
        await this.ProcessButtonAsyncAction(jqButton, this.#loadData);
        this.#configureEventHandlers();
    }

    Overlay(optionalExistingOverlay) {
        if (optionalExistingOverlay) {
            this.#uiOverlay = optionalExistingOverlay
        } else if (!this.#uiOverlay) {
            this.#uiOverlay = new Overlay();
        }
        return this.#uiOverlay;
    }

    async HandleClick(jqButton, selectedDataById) {
        if (this.#subClass) {
            if ('HandleClick' in this.#subClass) {
                await this.#subClass.HandleClick(jqButton, selectedDataById);
            } else {
                console.warn('Missing HandleClick on ', this.#subClass);
            }
        }
    }

    #configureEventHandlers() {
        let self = this;
        let jqLoadButton = $('#' + this.Id + this.#LOAD_BTN);
        jqLoadButton.off('click');
        jqLoadButton.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#loadData);
            $(this).parent().hide();
        });
    }

    #loadData = async() => {
        $('#' + this.Id + this.#DATA_TABLE_DIV_ID).html('<field-loading></field-loading>')
        let resultsJson = await this.#dbTableHandler.Get({"page": this.CurrentDataPage});
        if (('err' in resultsJson) && (resultsJson.err)) {
            this.ShowFlashMessage(resultsJson.err);
        } else {
            this.DataTableInstance.Render(); // creates container structure
            await this.DataTableInstance.RenderTable(resultsJson.data);
            this.#configureEventHandlers();
        }
    }

}