import PageBase from './PageBase.js';
import VerifyEmail from '../panels/User/VerifyEmail/VerifyEmail.js';
import JourneySelector from '../panels/User/JourneySelector/JourneySelector.js';


export default class UserHomePage extends PageBase {
    Title = 'User';
    #SECTIONS = ["VerifyEmailSection", "JourneysSection"];
    #whoAmI = null;
    #successCallback = null;

    constructor(whoAmI) {
        super();
        this.#whoAmI = whoAmI;
    }

    async Render() {
        await this.Init();
    }

    SetSuccessCallback(successCallback) {
        this.#successCallback = successCallback;
    }

    Init = async () => {
        let sectionsHtml = this.MakePageSectionsHtml(this.#SECTIONS);
        $('main#Content').html(sectionsHtml);
        await this.#populateSections();
        this.#configureClickHandlers();
    }

    // internals

    #configureClickHandlers() {
    }

    async #populateSections() {
        if (this.#whoAmI && !this.#whoAmI.isVerified) {
            let uiVerify = new VerifyEmail('#' + this.#SECTIONS[0], this.#whoAmI);
            await uiVerify.Render();
        }

        let startJourney = new JourneySelector('#' + this.#SECTIONS[1], this.#whoAmI);
        await startJourney.Render();

    }

}