import PageBase from './PageBase.js';
import Hero from  '../panels/Home/Hero/Hero.js'
import Contact from  '../panels/Home/Contact/Contact.js'

export default class ContactPage extends PageBase {
    Title = 'Contact';

    #SECTIONS = ["HeroSection", "AboutSection"];

    async Render() {
        await this.Init();
    }
    
    Init = async () => {
        let sectionsHtml = this.MakePageSectionsHtml(this.#SECTIONS);
        $('main#Content').html(sectionsHtml);
        await this.#populateSections();
        this.#configureClickHandlers();
    }
    
    // internals

    #configureClickHandlers ()  { // dont use arrow function def otherwise 'this' may not be valid
    }

    async #populateSections () {
        let uiHero = new Hero('#'+this.#SECTIONS[0]);
        await uiHero.Render();
        let uiAbout = new Contact('#'+this.#SECTIONS[1]);
        await uiAbout.Render();
    }
}
