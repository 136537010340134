import Store from'./Store';

export default class LocalDataTable { // base class for handling sets, items, config, etc
    static DataDefinitions = null; // child class must set this.DataDefinitions from MyApp.db.Schema.Config[tablename]
    static TableName = null; // child class must set this.TableName

    static GetAll = async function () {
        return await Store.GetAll(this.TableName);
    }

    static GetAllSinceDate = async function (dt) {
        return await Store.GetAllSinceDate(this.TableName, this.DataDefinitions.LocalFieldNames.LastUpdated, dt);
    }

    static Count = async function () {
        let allRecs = await this.GetAll();
        return allRecs.length;
    }

    static BulkInsert = async function (arrayOfJson) {
        return await Store.BulkInsert(this.TableName, arrayOfJson);
    }

    static GetByName = async function (nameValue) {
        let matchedRec;
        const INCLUDE_SYSTEM_RECORDS = true;
        let nameField = this.DataDefinitions.LocalFieldNames.Name;
        let allRecs = await this.GetAll(INCLUDE_SYSTEM_RECORDS);
        for (let i = 0; i < allRecs.length; i++) {
            let rec = allRecs[i];
            if (rec[nameField] == nameValue) {
                matchedRec = rec;
                break;
            }
        }
        return matchedRec;
    };

    static GetById = async function (localId) {
        let matchingRec = null;
        if (!this.DataDefinitions) {
            console.log('MyApp.db.Base.GetByLid() Something has gone very wrong!', localId);
        } else {
            //let fieldName = this.DataDefinitions.LocalFieldNames.LocalId
            //matchingRec = await Store.GetRecordWhere(this.TableName, fieldName, localId);
            matchingRec = await Store.GetRecord(this.TableName, localId);
        }
        return matchingRec;
    }

    static GetByRid = async function (remoteId) {
        let fieldName = this.DataDefinitions.LocalFieldNames.RemoteId
        return await Store.GetRecordWhere(this.TableName, fieldName, remoteId);
    }

    static GetPaginated = async function(nRecsPerPage, optionalPageNumber) {
        return await Store.GetTableDataPaginated(this.TableName, nRecsPerPage, optionalPageNumber);
    }

    static Save = async function (recJson, optionalSkipDtUpdate) {
        let localId = recJson[this.DataDefinitions.LocalFieldNames.LocalId];
        if (!localId) { // if set local id does not exists, insert, not update
            return await Store.PutRecord(this.TableName, recJson, optionalSkipDtUpdate);
        } else { // still might be an insert though
            let origRec = await this.GetByLid(localId);
            if (origRec) { // this is an update, map any fields in the orig into the new if they dont exist
                let origKeys = Object.keys(origRec);
                origKeys.forEach(function (key) { 
                    if (key in recJson === false) recJson[key] = origRec[key];
                });
            }
            return await Store.PutRecord(this.TableName, recJson, optionalSkipDtUpdate);
        }
    }

    static Clear = async function(AreYouSure) {
        Store.ClearTable(this.TableName, AreYouSure);
    }

    static DeleteByName = async (nameValue) => {
        let rec = await this.GetByName(nameValue);
        if (!rec) {
            console.warn('Error: Cannot locate record by name to delete: ' + nameValue);
        } else {
            if ((this.DataDefinitions.LocalFieldNames.RemoteId in rec) && (rec[this.DataDefinitions.LocalFieldNames.RemoteId] != 0)) {
                await Store.MarkDeletedNew(this.TableName, this.DataDefinitions.LocalFieldNames.IsDeleted, rec);
            } else {
                let localId = rec[this.DataDefinitions.LocalFieldNames.LocalId];
                await Store.DeleteRecord(this.TableName, localId);
            }
        }
    };

    // FIXME: this is not working correctly, this is not what I expected!
    static DeleteByLid = async function (localId) {
        console.warn('FIXME: this is not working correctly, this is not what I expected!');
        let rec = await this.GetByLid(localId);
        if (!rec) {
            console.warn('Error: Cannot locate record by localId to delete: ' + localId);
        } else {
            if ((this.DataDefinitions.LocalFieldNames.RemoteId in rec) && (rec[this.DataDefinitions.LocalFieldNames.RemoteId] != 0)) {
                await Store.MarkDeletedNew(this.TableName, this.DataDefinitions.LocalFieldNames.IsDeleted, rec);
            } else {
                await Store.DeleteRecord(this.TableName, localId);
            }
        }
    };

    static MarkDeleted = async function (recJson) {
        await Store.MarkDeletedNew(this.TableName, this.DataDefinitions.LocalFieldNames.IsDeleted, recJson);
    }
};