import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';

export default class Terms extends UiBase {
    constructor(where, whatJson) {
        where = where || 'footer';
        super(where, whatJson, TemplateHtml);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {

    }
}