export default [
    "The expert in anything was once a beginner",
    "Skill is only developed by hours and hours of work",
    //"Don't be afraid to give up the good to go for the great",
    "The only way to do great work is to love what you do",
    "Excellence is an art won by training and habituation",
    "Success is no accident, it is hard work",
    //"Continuous learning is the minimum requirement for success in any field",
    "The more I practice, the luckier I get",
    "The expert in anything was once a beginner",
    "The only way to do great work is to love what you do",
    "Skill is knowledge applied with precision and mastery",
    "It`s not whether you get knocked down, it's whether you get up",
    "Your attitude, not your aptitude, will determine your altitude",
    "Skill is the bridge between dreams and reality",
    "Every skill you acquire doubles your odds of success",
    "Skills unlock doors that lead to new possibilities",
    "Learn, grow, succeed",
    "Your potential is endless; develop your skills relentlessly",
    "Skills are the currency of the future",
    "Elevate your skills, elevate your life",
    "Today's skills are tomorrow's success stories",
    "Invest in your skills, they pay the best interest",
    "Skill up for a brighter future",
    "Skills are the seeds of success",
    "Your skills define your journey",
    "Craft your skills, craft your destiny",
    "Mastery is a journey, not a destination",
    "Skills - the secret sauce of achievement",
    "Empower yourself through continuous skill development",
    "Skills empower, inspire, and transform",
    "Skill is the engine, ambition is the fuel",
    "Skills sharpen, dreams brighten",
    "Skill development: where passion meets progress"
]