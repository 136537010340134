import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';

export default class Cookies extends UiBase {
    #BUTTON = 'RemoveAllCookies';

    constructor(where, whatJson) {
        where = where || 'footer';
        super(where, whatJson, TemplateHtml);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        $('#' + this.#BUTTON).off('click');
        $('#' + this.#BUTTON).on('click', async function (evt) {
            alert('not yet implemented!');
            //await self.ProcessButtonAsyncAction($(this), self.#ctaGetStarted);
        });
    }
}