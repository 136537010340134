export default class Url {

    static GetParameter(sParam) {
        let sPageURL = window.location.href;
        let splitUrl = sPageURL.split('?')
        if (splitUrl.length = 2) {
            let sURLVariables = splitUrl[1].split('&')
            for (let i = 0; i < sURLVariables.length; i++) {
                let sParameterName = sURLVariables[i].split('=');
                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        }
        return false;
    };
}