//
// TODO rename/relocate this as Hamburger menu
//

export class MainNavigation {
    static MODE = {
        "SITE": 0,
        "APP": 1
    };

    #NAV_BTNS_SELECTOR = 'header>nav>a';
    #NAV_BTNS_PREFIX = '#MainNav_';
    #NAV_BTN_MENU_TOGGLE = 'NavBtnOpenMenu';

    #currentMode = 0;

    constructor(mode) {
        mode = mode || 0;
        this.#currentMode = mode;
    }

    Init = (pageName) => {
        this.#setActiveNaveButton(pageName);
        this.#configureClickHandlers()
    }

    #setActiveNaveButton(pageName) {
        //console.log('Current: ', pageName);
        $(this.#NAV_BTNS_SELECTOR).removeClass('active');
        if (!pageName.startsWith('#googleauthcb?')) {
            //$(this.#NAV_BTNS_PREFIX + pageName).addClass('active');
        }
    }

    #configureClickHandlers() {
        let self = this;
        $('#' + this.#NAV_BTN_MENU_TOGGLE).off('click');
        $('#' + this.#NAV_BTN_MENU_TOGGLE).on('click', function (evt) {
            /*
            let jqButton = $(this);
            if (jqButton.hasClass('change')) { // close menu
                jqButton.removeClass('change');
                // removes the inline style instead of setting display: none; as otherwise display: none; overrides
                // the media query to show again when the page is made wider
                $('header>nav').attr("style", ""); 
            } else {
                jqButton.addClass('change'); // open menu
                $('header>nav').show();
            }*/
            self.#hamburgerToggle();
        });
    }

    #hamburgerToggle() {
        let jqBurgerButton = $('#' + this.#NAV_BTN_MENU_TOGGLE);
        if (jqBurgerButton.hasClass('change')) { // close menu
            jqBurgerButton.removeClass('change');
            // removes the inline style instead of setting display: none; as otherwise display: none; overrides
            // the media query to show again when the page is made wider
            $('header>nav').attr("style", "");
        } else {
            jqBurgerButton.addClass('change'); // open menu
            $('header>nav').show();
        }
    }
}
