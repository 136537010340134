import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';

export default class Header extends UiBase {

    constructor(where, whatJson) {
        where = where || 'header';
        super(where, whatJson, TemplateHtml);
    }

    async Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
    }
}