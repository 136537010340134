import LocalConfig from "../data/local/Config/LocalConfig.js";

export default class CurrentUser {
    static #cachedDetails = null;

    static IsFirstTimeRun = async () => {
        return await LocalConfig.IsFirstTimeRun();
    }

    static HasUserEverLoggedIn = async () => {
        let userDetails = await this.GetUserDetails()
        if (('email' in userDetails) && (userDetails.email) && (userDetails.email.length>0)) {
            return true;
        }
        else return false;
    }

    static FirstName = async () => {
        return await this.GetUserDetails().nickname;
    }

    static ClientKey = async (newClientKey) => {
        if (newClientKey) await this.SetUserDetails({ "clientKey": newClientKey });
        else return await this.GetUserDetails().clientKey;
    }

    static RefreshToken = async (newRefreshToken) => {
        if (newRefreshToken) await this.SetUserDetails({ "refreshToken": newRefreshToken });
        else return await this.GetUserDetails().refreshToken;
    }

    static Logout = async () => {
        await LocalConfig.Clear();
    }

    static GetUserDetails = async () => {
        if ((!this.#cachedDetails) || (Object.keys(this.#cachedDetails).length==0)) {
            console.warn('Loading CurrentUser details from LocalConfig table');
            this.#cachedDetails = await LocalConfig.LoadAll();
        }
        //console.log('GetUserDetails: ', this.#cachedDetails);
        if (Object.keys(this.#cachedDetails).length==0) {
            console.log('No user details found')
        }
        return this.#cachedDetails;
    }

    static SetUserDetails = async (dataJson) => {
        this.#cachedDetails = await LocalConfig.SaveAll(dataJson);
    }
}