import UiBase from '../../../UiBase.js';
import Template from './Template.html';
import CurrentUser from '../../../../utils/CurrentUser.js';
import Api from '../../../../utils/Api.js';

export default class RefreshToken extends UiBase {
    #INPUT_EMAIL = '_Email';
    #INPUT_KEY = '_ClientKey';
    #INPUT_TOKEN = '_Token';
    #BTN_CLEAR = '_Clear';
    #BTN_GETUSER = '_GetCurrentUser';
    #BTN_GETALL = '_Send';
    #DIV_DETAILS = '_CurrentUserDetails';

    constructor(where, whatJson,) {
        super(where, whatJson, Template);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        $('#' + this.Id + this.#BTN_CLEAR).off('click');
        $('#' + this.Id + this.#BTN_CLEAR).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#clear);
        });
        $('#' + this.Id + this.#BTN_GETUSER).off('click');
        $('#' + this.Id + this.#BTN_GETUSER).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#getUserDetails);
        });
        $('#' + this.Id + this.#BTN_GETALL).off('click');
        $('#' + this.Id + this.#BTN_GETALL).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#send);
        });
    }

    #getUserDetails = async () => {
        let userJson = await CurrentUser.GetUserDetails();
        $('#' + this.Id + this.#DIV_DETAILS).html(JSON.stringify(userJson,2,2));
        if ('email' in userJson) $('#' + this.Id + this.#INPUT_EMAIL).val(userJson.email);
        else $('#' + this.Id + this.#INPUT_EMAIL).val('');
        if ('clientKey' in userJson) $('#' + this.Id + this.#INPUT_KEY).val(userJson.clientKey);
        else $('#' + this.Id + this.#INPUT_KEY).val('');
        if ('refreshToken' in userJson) $('#' + this.Id + this.#INPUT_TOKEN).val(userJson.refreshToken);
        else $('#' + this.Id + this.#INPUT_TOKEN).val();    
        console.log('here');    
    }

    #clear = async () => {
        await CurrentUser.Logout();
        let userJson = await CurrentUser.GetUserDetails();
        $('#' + this.Id + this.#DIV_DETAILS).html(JSON.stringify(userJson,2,2));
    }

    #send = async () => {
        let email = $('#' + this.Id + this.#INPUT_EMAIL).val();
        let clientKey = $('#' + this.Id + this.#INPUT_KEY).val();
        let refreshToken = $('#' + this.Id + this.#INPUT_TOKEN).val();
        let data =  {
            "clientKey": clientKey,
            "refreshToken": refreshToken,
            "email": email,
            //"name": userDetails.name
        };
        let response = await Api.UseRefreshToken(data);
        console.log('Refresh Token Test Success: ', response);
        let userJson = await CurrentUser.GetUserDetails();
        $('#' + this.Id + this.#DIV_DETAILS).html(JSON.stringify(userJson,2,2));
    }

}