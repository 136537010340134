export default {
    "role": {
        "displayType": "select",
        "values": {
            0: { "displayName": "No role defined", "internalName": "none" },
            10: { "displayName": "Reader", "internalName": "reader" },
            20: { "displayName": "Creator", "internalName": "creator" },
            30: { "displayName": "Admin", "internalName": "admin"  },
            40: { "displayName": "Systems Admin", "internalName": "systemsAdmin"  },
        }
    },
    "subscriptionType": {
        "displayType": "select",
        "values": {
            0: { "displayName": "Free" },
            10: { "displayName": "Silver" },
            20: { "displayName": "Gold" },
            30: { "displayName": "Platinum" },
        }
    }
}