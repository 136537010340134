import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import JourneyManager from '../../../journeys/JourneyManager.js';

export default class JourneySelector extends UiBase {
    #JOURNEYS_DIV = '_journeys';

    constructor(where, whatJson) {
        super(where, whatJson, TemplateHtml);
    }

    async Render() {
        super.Render();
        let allJourneysJson = await JourneyManager.AllAvailableJourneys();
        let keyJourneyCategories = Object.keys(allJourneysJson);
        let journeysHtml = 'No journeys available';
        if (keyJourneyCategories.length > 0) {
            journeysHtml = '';
            for (let i = 0; i < keyJourneyCategories.length; i++) {
                let journeyCategory = keyJourneyCategories[i];
                journeysHtml += '<div class="panel"><h4>' + journeyCategory + '</h4><div class="flexRowCenterCenter" data-category="' + journeyCategory + '">';
                let journeysJson = allJourneysJson[journeyCategory];
                let journeyClassNames = Object.keys(journeysJson);
                for (let j=0;j<journeyClassNames.length;j++) {
                    let journeyClassName = journeyClassNames[j];
                    let journey = journeysJson[journeyClassName];
                    journeysHtml += journey.HtmlButton();
                }
                journeysHtml += '</div></div>';
            }
        }
        $('#' + this.Id + this.#JOURNEYS_DIV).html(journeysHtml);
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        let jqButtons = $('#' + this.Id + this.#JOURNEYS_DIV).find('button');
        jqButtons.off('click');
        jqButtons.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#start);
        });
    }

    #start = async (jqButton) => {
        let jName = jqButton.data('name');
        let jCat = jqButton.parent().parent().data('category');
        console.log(jName);
        await JourneyManager.StartJourney(jCat, jName);
    }
}