import Measure from '../../../data/api/Measure.js';
import AdminPanelBase from './Shared/AdminPanelBase.js';

export default class MeasureAdmin extends AdminPanelBase {
    
    constructor(where, whatJson) {
        let buttons = '';
        whatJson.sectionTitle = Measure.GetTableSummary().displayNamePlural;
        whatJson.sectionDescription = Measure.GetTableSummary().description;
        super(where, whatJson, Measure, buttons);
    }

}