import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import Mappings from './Mappings.js';
import Auth from '../../../../utils/Auth.js';

export default class AccountDetails extends UiBase {
    #USER_ID = '_userId';
    #EMAIL_INPUT = '_email';
    #NAME_INPUT = '_nickname';
    #UPDATE_USER_BTN = '_updateUser';

    constructor(where, whatJson) {
        super(where, whatJson, TemplateHtml, Mappings);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        let jqButton = $('#' + this.Id + this.#UPDATE_USER_BTN);
        jqButton.off('click');
        jqButton.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#updateUser);
        });
    }

    #updateUser = async() => {
        let userId = $('#' + this.Id + this.#USER_ID).val();
        let email = $('#' + this.Id + this.#EMAIL_INPUT).val();
        let nickname = $('#' + this.Id + this.#NAME_INPUT).val();
        let resultsJson = await Auth.UpdateAccount(userId, email, nickname);
        if (('err' in resultsJson) && (resultsJson.err)) {
            this.ShowFlashMessage(resultsJson.err);
        }
    }
}