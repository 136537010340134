import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import Auth from '../../../../utils/Auth.js';

export default class WhoAmI extends UiBase {
    #BTN_WHOAMI = 'AuthFindWhoAmI';
    #BTN_TEST_REFRESH_TOKEN = 'AuthWhoAmICheckRefreshToken';
    #BTN_CLEAR = 'AuthClearFindWhoAmI';
    #SPAN_WHOAMI = 'AuthWhoAmI';


    constructor(where, whatJson) {
        where = where || 'main#Content';
        super(where, whatJson, TemplateHtml);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        $('#' + this.#BTN_WHOAMI).off('click');
        $('#' + this.#BTN_WHOAMI).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#whoami);
        });
        $('#' + this.#BTN_TEST_REFRESH_TOKEN).off('click');
        $('#' + this.#BTN_TEST_REFRESH_TOKEN).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#resultJson);
        });
        $('#' + this.#BTN_CLEAR).off('click');
        $('#' + this.#BTN_CLEAR).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#clearWhoAmI);
        });
    }

    #whoami = async () => {
        let whoAmIJson = await Auth.WhoAmI();
        if (!whoAmIJson.err) {
            this.#setResults('API', whoAmIJson.data);
        } else {
            this.#setResults('API Error', whoAmIJson.err);
        }
    }

    #resultJson = async () => {
        let resultJson = await Auth.TestRefreshToken();
        if (!resultJson.err) {
            this.#setResults('API', resultJson.data);
        } else {
            this.#setResults('API Error', resultJson.err);
        }
        return resultJson;
    }

    #clearWhoAmI = async () => {
        $('#' + this.#SPAN_WHOAMI).html('');
    }

    #setResults = (from, data) => {
        let html = '<div>' + from + '</div><div><pre>' + JSON.stringify(data,2,2) + '</pre></div>';
        $('#' + this.#SPAN_WHOAMI).html(html);
    }

}