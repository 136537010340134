import LocalDataTable from '../Shared/LocalDataTable.js'
import Schema from '../Shared/Schema.js';
import PresetNames from './PresetNames.js';
import KeyMappings from './KeyMappings.js';

export default class LocalConfig extends LocalDataTable {
    static DEFAULT_LANGUAGE = 'en-GB';
    static DEFAULT_CURRENCY = 'GBP';
    //static Store = MyApp.db.Store;
    static TableName = null;

    static IsFirstEverRun = true;
    static HasUserEverLoggedIn = false;
    static #IsInitialised = false;

    static Initialise = async () => {
        if (this.#IsInitialised) return;
        this.Schema = Schema.Config;
        this.TableName = this.Schema.LocalTableName;
        this.#IsInitialised = true;
    }

    static IsFirstTimeRun = async () => {
        let existingRecsArray = await this.GetAll();
        if (existingRecsArray && (existingRecsArray.length > 0)) return false;
        else return true;
    }

    static HasUserEverLoggedIn = async () => {
        let email = await this.loadData(PresetNames.Email);
        if (email && (email.length > 0)) return true;
        else return false;
    }

    static Clear = async () => {
        const I_AM_SURE = true;
        await super.Clear(I_AM_SURE);
    }

    static SaveAll = async (keysAndValues) => {
        // incoming is key/value json
        // this needs merging with existing which is an array(in the case of a partial update) and then the 
        // existing recs cleared and 
        if (!this.#IsInitialised) await this.Initialise();
        let resultRecs = [];
        try {
            let sanitisedIncomingKeysAndValues = this.#sanitizeJson(keysAndValues);
            // get existing data and if there is any, merge keysAndValues into it
            let existingRecsArray = await this.GetAll();
            let existingAsKeysAndValues = this.#recArrayToJson(existingRecsArray);
            let sanitisedExistingKeysAndValues = this.#sanitizeJson(existingAsKeysAndValues);
            let mergedJSON = Object.assign(sanitisedExistingKeysAndValues, sanitisedIncomingKeysAndValues); // 2nd JSON arg takes precedence
            let arrayOfJson = this.#jsonKeyValuesToRecArray(mergedJSON)
            await this.Clear();
            resultRecs = await this.BulkInsert(arrayOfJson);
        } catch (error) {
            console.warn('Error in LocalConfig.SaveAll()');
            console.warn(error);
        }
        let updatedAsKeysAndValues = this.#recArrayToJson(resultRecs); // turn it back into keys and values
        return updatedAsKeysAndValues;
    }

    static LoadAll = async () => {
        if (!this.#IsInitialised) await this.Initialise();
        let existingRecsArray = await this.GetAll();
        let existingAsKeysAndValues = this.#recArrayToJson(existingRecsArray);
        return existingAsKeysAndValues;
    }

    // Internals

    // sanitise incoming JSON data by looking at the preset keys we require and build a new 
    // JSON object that includes only those in our KeyMappings
    static #sanitizeJson(keysAndValues) {
        let sanitisedJson = {};
        let requiredDataKeys = Object.keys(KeyMappings);
        if (typeof keysAndValues === 'string') keysAndValues = JSON.parse(keysAndValues);
        for (let i = 0; i < requiredDataKeys.length; i++) {
            let incomingKey = requiredDataKeys[i];
            let configTableKey = KeyMappings[incomingKey]
            if (incomingKey in keysAndValues) {
                sanitisedJson[configTableKey] = keysAndValues[incomingKey];
            }
        }
        return sanitisedJson;
    }

    static #recArrayToJson(recArray) {
        let existingAsKeysAndValues = {};
        if (recArray && recArray.length > 0) {
            for (let i = 0; i < recArray.length; i++) {
                let recJson = recArray[i];
                let key = recJson[this.Schema.LocalFieldNames.Name];
                let val = recJson[this.Schema.LocalFieldNames.Value];
                existingAsKeysAndValues[key] = val;
            }
        }
        return existingAsKeysAndValues;
    }

    static #jsonKeyValuesToRecArray(keysValues) {
        let recArray = [];
        let keys = Object.keys(keysValues);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let val = keysValues[key];
            let recJson = {};
            recJson[this.Schema.LocalFieldNames.Name] = key;
            recJson[this.Schema.LocalFieldNames.Value] = val;
            recArray.push(recJson);
        }
        return recArray;
    }

    static #digestMessage = async (message) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(message);
        const hash = await crypto.subtle.digest("SHA-256", data);
        return hash;
    }

};