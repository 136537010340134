import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import Auth from '../../../../utils/Auth.js';

export default class VerifyEmail extends UiBase {
    #USER_ID = '_userId';
    #RESEND_VERIFY_EMAIL = '_ResendVerifyEmailMessage';
    #CLOSE_BTN_ID = 'CloseVerifyEmailMessage';
    
    constructor(where, whatJson) {
        super(where, whatJson, TemplateHtml);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        //console.log('--------->'+'#' + this.Id + this.#RESEND_VERIFY_EMAIL)
        let jqButton = $('#' + this.Id + this.#RESEND_VERIFY_EMAIL);
        jqButton.off('click');
        jqButton.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#resendEmail);
        });
        let jqCloseButton = $('#' + this.#CLOSE_BTN_ID);
        jqCloseButton.off('click');
        jqCloseButton.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#close);
        });
    }

    #resendEmail = async() => {
        let userId = $('#' + this.Id + this.#USER_ID).val();
        //console.log('resend email verification link');
        let resultsJson = await Auth.ResendEmailVerificationLink(userId);
        if (('err' in resultsJson) && (resultsJson.err)) {
            this.ShowFlashMessage(resultsJson.err);
        } else {
            this.ShowFlashMessage(resultsJson.data);
        }
    }

    #close = () => {
        this.SlideUp();
    }
}