import PersonRating from '../../../data/api/PersonRating.js';
import AdminPanelBase from './Shared/AdminPanelBase.js';

export default class PersonRatingAdmin extends AdminPanelBase {
    
    constructor(where, whatJson) {
        let buttons = '';
        whatJson.sectionTitle = PersonRating.GetTableSummary().displayNamePlural;
        whatJson.sectionDescription = PersonRating.GetTableSummary().description;
        super(where, whatJson, PersonRating, buttons);
    }

}