import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import CurrentUser from '../../../../utils/CurrentUser.js';
import Auth from '../../../../utils/Auth.js';

export default class Login extends UiBase {
    #BTN_LOGIN = 'AuthLogin';
    #BTN_FORGOT_PWD = 'AuthForgotPassword';
    #INPUT_LOGIN_EMAIL = 'AuthLoginEmail';
    #INPUT_LOGIN_PWD = 'AuthLoginPassword';
    #successCallback = null;

    constructor(where, whatJson) {
        where = where || 'main#Content';
        super(where, whatJson, TemplateHtml);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    SetSuccessCallback(successCallback) {
        this.#successCallback = successCallback;
    }

    #configureEventHandlers() {
        let self = this;
        $('#' + this.#BTN_LOGIN).off('click');
        $('#' + this.#BTN_LOGIN).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#login);
        });
        $('#'+this.#INPUT_LOGIN_PWD).off('keyup');
        $('#'+this.#INPUT_LOGIN_PWD).on('keyup', function(evt) { // auto click Login button when pressing enter on password
            if(evt.which == 13) $('#' + self.#BTN_LOGIN).trigger('click');
        });
        $('#' + this.#BTN_FORGOT_PWD).off('click');
        $('#' + this.#BTN_FORGOT_PWD).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#forgotPassword);
        });
    }

    #login = async () => {
        let email = $('#' + this.#INPUT_LOGIN_EMAIL).val();
        let pwd = $('#' + this.#INPUT_LOGIN_PWD).val();
        let loginJson = await Auth.Login(email, pwd);
        if (!loginJson.err) {
            await CurrentUser.SetUserDetails(loginJson.data);
            console.log('LoginJson: ', loginJson);
            let whoJson = await Auth.WhoAmI();// supplemental user data from whoami
            console.log('Who: ', whoJson);
            if (this.#successCallback) {
                this.#successCallback('/#user');
            }
        } else {
            this.ShowFlashMessage('Login failed.</br>Check your email and password.');
            console.warn(loginJson);
        }
    }

    #forgotPassword= async () => {
        let email = $('#' + this.#INPUT_LOGIN_EMAIL).val();
        let resultJson = await Auth.ForgotPassword(email);
        if (!resultJson.err) {
            //window.location.href="/myaccount";
            console.log('resultJson: ', resultJson);
            this.ShowFlashMessage('Check your email for a link to reset your password');
        } else {
            this.ShowFlashMessage(resultJson.err);
        }
    }
}