import Api from '../../../utils/Api.js';
import DbTableBase from '../Shared/DbTableBase.js';

export default class User extends DbTableBase {
    static JSON_TABLE_NAME = 'User';
    static API_TABLE_NAME = 'user';

    static #ROUTES = {
        "GETBYID": "/users/GetById/",
        "GETALL": "/users/GetAll",
        "CREATE": "/users/PostCreate",
        "UPDATE": "/users/PostUpdate/",
        "DELETE": "/users/DeleteById/",
        "VERIFY_EMAIL": "/auth/other/verifyEmailForce/",
        "UNVERIFY_EMAIL": "/auth/other/unverifyEmailForce/",
        "RESET_PWD": "/auth/other/resetPasswordForce"
    };

    static Get = async (queryJson) => {
        const response = await Api.Get(this.#ROUTES.GETALL, queryJson);
        let jsonResults = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        return jsonResults;
    }

    static async GetById(tableName, id, queryJson) { // tablename is irrelvant but we retain it to be compatible with the other dbTable classes
        queryJson = queryJson || {};
        const response = await Api.Get(this.#ROUTES.GETBYID + id, queryJson);
        let jsonResults = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        return jsonResults;
    }

    static async GetAll(tableName, queryJson) {
        return await super.GetAll(tableName, queryJson);
    }

    static async AddRecord(userData) {
        let resultJson = { "err": null, "data": null };
        let validationErrorMsgsArray = this.ValidateAddUserData(userData);
        if (validationErrorMsgsArray.length == 0) {
            const apiRoute = this.#ROUTES.CREATE.replace('{TableName}', this.API_TABLE_NAME);
            const response = await Api.Post(apiRoute, userData);
            resultJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        } else {
            validationErrorMsgsArray.unshift('<h3>Failed to add record</h3>');
            resultJson.err = validationErrorMsgsArray.join('<br />');
        }
        return resultJson;
    }

    static async UpdateRecord(userData) {
        let resultJson = { "err": null, "data": null };
        let validationErrorMsgsArray = this.ValidateAddUserData(userData);
        if (validationErrorMsgsArray.length == 0) {
            const apiRoute = this.#ROUTES.UPDATE.replace('{TableName}', this.API_TABLE_NAME);
            const response = await Api.Post(apiRoute + userData.id, userData);
            resultJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        } else {
            validationErrorMsgsArray.unshift('<h3>Failed to update record (' + userData.id + ')</h3>');
            resultJson.err = validationErrorMsgsArray.join('<br />');
        }
        return resultJson;
    }

    static async DeleteRecord(userData) {
        let resultJson = { "err": null, "data": null };
        if ('id' in userData) {
            const apiRoute = this.#ROUTES.DELETE.replace('{TableName}', this.API_TABLE_NAME);
            const response = await Api.Post(apiRoute + userData.id);
            resultJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        }
        if (!resultJson.data) {
            let errTitle = '<h3>Failed to delete record (' + userData.id + ')</h3>';
            resultJson.err = errTitle + resultJson.err;
        }
        return resultJson;
    }
    // Other API wrappers

    static async ForceEmailVerification(id) {
        let response = await Api.Get(this.#ROUTES.VERIFY_EMAIL + id);
        let resultJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        return resultJson;
    }

    static async ForceEmailUnverification(id) {
        let response = await Api.Get(this.#ROUTES.UNVERIFY_EMAIL + id);
        let resultJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        return resultJson;
    }

    static async ForcePwdReset(id, pwd) {
        let response = await Api.Post(this.#ROUTES.RESET_PWD, {"id": id, "pwd": pwd});
        let resultJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        return resultJson;
    }


}

