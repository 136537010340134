import JourneyBase from "../JourneyBase.js";

export default class ShowSystemsAdminPage extends JourneyBase {
    static Name = 'ShowSystemsAdminPage';
    static Description = 'Admin';
    static Icon = '<i class="fa-solid fa-toolbox"></i>';

    static async Start() {
        let pageTarget = '/#systemsadmin';
        window.location.href = pageTarget;
    }
}