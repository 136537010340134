import PageBase from './PageBase.js';
import Hero from  '../panels/Home/Hero/Hero.js'
import Unknown from  '../panels/Home/Unknown/Unknown.js'

export default class UnknownPage extends PageBase {
    Title = 'Unknown';
    #SECTIONS = ["HeroSection", "AboutSection"];

    async Render() {
        await this.Init();
    }
    
    Init = async () => {
        let sectionsHtml = this.MakePageSectionsHtml(this.#SECTIONS);
        $('main#Content').html(sectionsHtml);
        await this.#populateSections();
        this.#configureClickHandlers();
    }
    
    // internals

    #configureClickHandlers ()  { // dont use arrow function def otherwise 'this' may not be valid
    }

    async #populateSections () {
        let uiHero = new Hero('#'+this.#SECTIONS[0]);
        await uiHero.Render();
        let uiAbout = new Unknown('#'+this.#SECTIONS[1]);
        await uiAbout.Render();
    }
}
