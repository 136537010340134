import PageBase from './PageBase.js';
import UserAccounts from '../panels/Admin/UserAccounts.js';
import ManageConfig from '../panels/Test/ManageConfig/ManageConfig.js';
import RefreshToken from '../panels/Test/RefreshToken/RefreshToken.js';
import WhoAmI from '../panels/User/WhoAmI/WhoAmI.js';
import Logout from '../panels/User/Logout/Logout.js';

export default class SystemsAdminPage extends PageBase {
    Title = 'Systems Test';
    #SECTIONS = {
        "LOADING": "TestLoading",
    };

    #PANELS = [
        {"divId": "TestUserAccounts", 'es6Class': UserAccounts},
        {"divId": "TestManageConfig", 'es6Class': ManageConfig},
        {"divId": "TestRefreshToken", 'es6Class': RefreshToken},
        {"divId": "TestWhoAmi", 'es6Class': WhoAmI},
        {"divId": "TestLogout", 'es6Class': Logout},
    ];

    #whoAmI = null;

    constructor(whoAmI) {
        super();
        this.#whoAmI = whoAmI;
    }

    async Render() {
        await this.Init();
    }

    Init = async () => {
        let sectionsDivsArray = this.#sectionsAsDivArray(this.#SECTIONS.LOADING, this.#PANELS);
        let sectionsHtml = this.MakePageSectionsHtml(sectionsDivsArray);
        $('main#Content').html(sectionsHtml);
        await this.#populateSections(this.#whoAmI);
        this.#configureClickHandlers();
    }

    #configureClickHandlers() {
    }

    #sectionsAsDivArray(verifyEmailSectionDivId, panelsArrayOfJson) {
        let sectionDivs = [];
        sectionDivs.push(verifyEmailSectionDivId);
        for (let i=0;i<panelsArrayOfJson.length;i++) {
            sectionDivs.push(panelsArrayOfJson[i].divId)
        }
        return sectionDivs;
    }
    async #populateSections(whoJson) {
        if (whoJson && !whoJson.isVerified) {
            let uiVerify = new VerifyEmail('#' + this.#SECTIONS[0], whoJson);
            await uiVerify.Render();
        }
        for (let i=0;i<this.#PANELS.length;i++) {
            let panelJson = this.#PANELS[i];
            let divId = panelJson.divId;
            let es6Class = panelJson.es6Class;
            let uiAdminPanel = new es6Class('#'+divId, whoJson);
            await uiAdminPanel.Render();
        }
    }

}