import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import PersonDetailsTemplate from './PersonDetailsTemplate.html';
//import Auth from '../../../../utils/Auth.js';
import Person from '../../../../data/api/Person.js';
import Realm from '../../../../data/api/Realm.js';

export default class PersonDetails extends UiBase {
    #CONTENT_CONTAINER_DIV_ID = 'PersonDetailsContainer';
    #REALME_NAME_DIV_ID = 'DefaultRealmName';
    #BTN_EDIT = ' EditPersonDetails';
    userEmail = null;

    constructor(where, whatJson) {
        super(where, whatJson, TemplateHtml);
        if (whatJson && ('email' in whatJson)) this.userEmail = whatJson.email;
        //console.log(whatJson);
    }

    async Render() {
        super.Render();
        if (this.userEmail) {
            let resultsJson = await Person.GetWhere({ Email: this.userEmail });
            if (resultsJson.err) {
                $('#' + this.#CONTENT_CONTAINER_DIV_ID).html('An error occurred');
                console.log(resultsJson);
            } else if (resultsJson.data.rows.length == 0) {
                $('#' + this.#CONTENT_CONTAINER_DIV_ID).html('Could not locate a person record');
                console.log(resultsJson);
            } else {
                let personRec = resultsJson.data.rows[0];
                await this.#populateFromPersonRecord(personRec);
            }
            //console.log(resultsJson);
        }
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        //console.log('--------->'+'#' + this.Id + this.#RESEND_VERIFY_EMAIL)
        let jqButton = $('#' + this.Id + this.#BTN_EDIT);
        jqButton.off('click');
        jqButton.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#close);
        });
    }

    async #populateFromPersonRecord(personRec) {
        let selector = '#' + this.#CONTENT_CONTAINER_DIV_ID;
        let uiPersonRecDetails = new UiBase(selector, personRec, PersonDetailsTemplate);
        let html = uiPersonRecDetails.GenerateHtml();
        $('#' + this.#CONTENT_CONTAINER_DIV_ID).html(html);
        //console.log(personRec);
        let realmsJson = await Realm.GetWhere({ id: personRec.DefaultRealmId });
        if (realmsJson.err) {
            console.log(resultsJson);
        } else if (realmsJson.data.rows.length == 0) {
            console.log(resultsJson);
        } else {
            let realmRec = realmsJson.data.rows[0];
            $('#'+this.#REALME_NAME_DIV_ID).html(realmRec.Name);
            console.log(realmRec);
        }
    }

    #close = () => {
        this.SlideUp();
    }
}