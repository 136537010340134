import UiBase from "../UiBase.js";

export default class JourneyBase extends UiBase {
    static Name = 'ReplaceMyName';
    static Description = 'This is a class intended as the parent for all journeys';
    static Icon = '<i class="fa-solid fa-font-awesome"></i>';

    static HtmlButton () {
        return '<div><button class="actionButton" data-name="' + this.Name + '">' + this.Icon + '<br />' + this.Description + '</button></div>';
    }

    static async Start() {

    }

}