import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import Auth from '../../../../utils/Auth.js';

// Note: src/ui/wePages/PageHandler.js handles the callback when google auth completes successfully

export default class GoogleLogin extends UiBase {
    #BTN_GOOGLE_LOGIN = 'AuthGoogleLogin';
    #INPUT_LOGIN_EMAIL = 'AuthGoogleLoginEmail';
    #successCallback = null;

    constructor(where, whatJson) {
        where = where || 'main#Content';
        super(where, whatJson, TemplateHtml);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    SetSuccessCallback(successCallback) {
        this.#successCallback = successCallback;
    }

    #configureEventHandlers() {
        let self = this;
        $('#' + this.#BTN_GOOGLE_LOGIN).off('click');
        $('#' + this.#BTN_GOOGLE_LOGIN).on('click', async function (evt) {
            // capture A href click so we can show a loading indicator then let it redirect 
            let jqButton = $(this);
            jqButton.prop('disabled', true);
            jqButton.addClass('disabledButton');
            jqButton.html('<button-loading></button-loading>');
        });
        $('#'+this.#INPUT_LOGIN_EMAIL).off('keyup');
        $('#'+this.#INPUT_LOGIN_EMAIL).on('keyup', function(evt) { // auto click Login button when pressing enter on password
            if(evt.which == 13) $('#' + self.#BTN_GOOGLE_LOGIN).trigger('click');
        });
    }


}