import { openDB, deleteDB } from 'idb/with-async-ittr';
//import idb from 'idb';
import Schema from './Schema.js';


export default class Store {
    static DB_NAME = 'skillscoach';
    static DB_VERSION = 1;
    static WRITABLE_MODE = 'readwrite';
    static schema = Schema
    static NULL_REMOTE_ID = -1;
    static IS_DELETED_FIELD = 'IsDeleted';
    static eventHandler = null;
    static EVT_SRC = 'Database';
    static EVT_TYPE = 'Changed';
    static EVT_ACTION_INSERTED = 'Inserted';
    static EVT_ACTION_UPDATED = 'Updated';
    static EVT_ACTION_DELETED = 'Deleted';
    static emitEvents = false;

    // See https://github.com/jakearchibald/idb

    static Initialise = async (optionalEventHandler) => {
        // typically optionalEventHandler is the MyApp.Utils.Events.Emit function 
        this.eventHandler = optionalEventHandler;
        if (this.eventHandler) this.emitEvents = true;
        await this.openDbConnection(); // we dont need an open db conn here, but will do it anyway to ensure any required db upgrade to is done
    };

    static DisableEmitEvents = () => this.emitEvents = false;

    static EnableEmitEvents = () => this.emitEvents = true;

    static EmitEventNotification = async (action, value) => {
        if ((this.eventHandler) && (this.emitEvents)) await this.eventHandler(this.EVT_SRC, {
            "Type": "Changed",
            "Action": action,
            "Value": value
        });
    }

    static GetRecord = async (tableName, localId) => await this.getByLocalId(tableName, localId);

    static GetRecordWhere = async (tableName, fieldName, fieldValue) => await this.getFirstViaIndex(tableName, fieldName, fieldValue);

    static GetAll = async (tableName) => await this.getAll(tableName);

    static GetAllSinceDate = async (tableName, dtFieldName, dtFrom) => await this.getAllTableDataSinceDate(tableName, dtFieldName, dtFrom);

    static GetAllWhere = async (tableName, fieldName, fieldValue) => await this.getAllDataMatchingIndex(tableName, fieldName, fieldValue);

    static GetMostRecentBySetLids = async (tableName, indexFieldName, setLidsArray, setLidFieldName, maxRecords, optionalIncludeDeleted) => {
        return await this.getMostRecentBySetLids(tableName, indexFieldName, setLidsArray, setLidFieldName, maxRecords, optionalIncludeDeleted);
    }

    static GetTableDataPaginated = async (tableName, pageLength, optionalPageNumber) => await this.getTableDataPaginated(tableName, pageLength, optionalPageNumber);

    static Count = async (tableName) => await this.count(tableName);

    static PutRecord = async (tableName, recJson, optionalSkipDtUpdate) => {
        let localIdField = this.schema[tableName].LocalFieldNames.LocalId;
        // get rec by local ID if it exists - this determines whether we are inserting or updating
        let rec = null;
        if (localIdField in recJson) {
            rec = await this.getItem(tableName, recJson[localIdField]);
        }
        if (!rec) {
            rec = await this.insertRecord(tableName, recJson, optionalSkipDtUpdate);
            this.EmitEventNotification(this.EVT_ACTION_INSERTED, rec);
        } else {
            rec = await this.updateRecord(tableName, recJson, optionalSkipDtUpdate);
            this.EmitEventNotification(this.EVT_ACTION_UPDATED, rec);
        }
        return rec;
    };

    static PutRecordWhere = async (tableName, recJson, whereJson, optionalSkipDtUpdate) => {
        let rec = await this.getFirstViaIndex(tableName, whereJson.FieldName, whereJson.FieldValue);
        if (!rec) {
            rec = await this.insertRecord(tableName, recJson, optionalSkipDtUpdate);
            this.EmitEventNotification(this.EVT_ACTION_INSERTED, rec);
        } else {
            // transfer rec fields to recJson if it doesnt have them
            let recFieldNames = Object.keys(rec);
            for (var i = 0; i < recFieldNames.length; i++) {
                let recFieldName = recFieldNames[i];
                if (recFieldName in recJson === false) recJson[recFieldName] = rec[recFieldName];
            }
            rec = await this.updateRecord(tableName, recJson, optionalSkipDtUpdate);
            this.EmitEventNotification(this.EVT_ACTION_UPDATED, rec);
        }
        return rec;
    };

    static BulkInsert = async (tableName, recJsonArray) => {
        await this.insertMultipleRecords(tableName, recJsonArray);
        this.EmitEventNotification(this.EVT_ACTION_INSERTED, recJsonArray);
    };

    static MarkDeletedNew = async (tableName, isDeletedFieldName, recJson) => {
        recJson[isDeletedFieldName] = true;
        let rec = await this.updateRecord(tableName, recJson);
        this.EmitEventNotification(this.EVT_ACTION_DELETED, rec);
        return rec;
    };

    static MarkDeleted = async (tableSchema, recJson) => {
        recJson[IS_DELETED_FIELD] = true;
        let rec = await this.updateRecord(tableSchema.TableName, recJson);
        this.EmitEventNotification(this.EVT_ACTION_DELETED, rec);
        return rec;
    };

    static DeleteRecord = async (tableName, itemLocalId) => {
        let rec = await this.deleteRecord(tableName, itemLocalId);
        this.EmitEventNotification(this.EVT_ACTION_DELETED, itemLocalId);
        return rec
    }

    // replaces dc.DeleteAll
    static ClearTable = async (tName, areYouReallySure) => {
        if (areYouReallySure) {
            let dbConnection = await this.openDbConnection();
            //console.warn('!!! Clearing local table contents: ' + tName);
            let tx = await dbConnection.transaction(tName, this.WRITABLE_MODE);
            let store = await tx.store;
            await store.clear();
            await tx.done;
        }
        this.EmitEventNotification(this.EVT_ACTION_DELETED);
    }

    // replaces dc.DeleteAll
    static DeleteAll = async (areYouReallySure) => {
        if (areYouReallySure) {
            let keys = Object.keys(MyApp.db.Schema.Config);
            let self = this;
            keys.forEach(async function (key) {
                let dataDef = MyApp.db.Schema.Config[key];
                let tName = dataDef.LocalTableName;
                let dbConnection = await self.openDbConnection();
                console.warn('!!! Clearing local table contents: ' + tName);
                let tx = await dbConnection.transaction(tName, self.WRITABLE_MODE);
                let store = await tx.store;
                await store.clear();
                await tx.done;
            });
        }
        this.EmitEventNotification(this.EVT_ACTION_DELETED);
    }

    // internals (though not private!)

    // DB and table creation and upgrade 

    static openDbConnection = async () => {
        let self = this;
        let dbConnection = null;
        try {
            dbConnection = await openDB(this.DB_NAME, this.DB_VERSION, {
                upgrade(db, oldVersion, newVersion, transaction, event) {
                    console.log('IDB upgrade from oldVersion: ' + oldVersion + ' to newVersion: ' + newVersion);
                    self.createTables(db);
                },
                blocked(currentVersion, blockedVersion, event) {
                    console.warn('IDB blocked blockedVersion: ' + blockedVersion + ' to currentVersion: ' + currentVersion);
                },
                blocking(currentVersion, blockedVersion, event) {
                    console.warn('IDB blocking blockedVersion: ' + blockedVersion + ' to currentVersion: ' + currentVersion);
                },
                terminated() {
                    console.warn('IDB terminated');
                },
            });
        } catch (error) {
            console.warn('Error in Store.openDbConnection():');
            console.warn(error);
        }
        return dbConnection;
    }

    static createTables = async (dbConn) => {
        let datasetDefinitions = Object.keys(this.schema);
        for (let i = 0; i < datasetDefinitions.length; i++) {
            let datasetDef = this.schema[datasetDefinitions[i]];
            let tableName = datasetDef.LocalTableName;
            if (!dbConn.objectStoreNames.contains(tableName)) {
                await this.createTable(dbConn, tableName, datasetDef);
            }
        }
    }

    static createTable = async (dbConn, tableName, datasetDef) => {
        const tableStore = dbConn.createObjectStore(tableName, {
            keyPath: datasetDef.LocalFieldNames.LocalId,
            autoIncrement: datasetDef.LocalAutoIncrement,
        });
        for (let j = 0; j < datasetDef.LocalIndices.length; j++) {
            let indexFieldName = datasetDef.LocalIndices[j];
            tableStore.createIndex(indexFieldName, indexFieldName);
        }
        if ('CompoundIndexes' in datasetDef) { // added 12/6 to support compound indexes, DB_VERSION increated to 3
            for (let k = 0; k < datasetDef.CompoundIndexes.length; k++) {
                let compoundIndex = datasetDef.CompoundIndexes[k];
                tableStore.createIndex(compoundIndex.indexName, compoundIndex.fieldsToIndex);
            }
        }
    }

    // DB record access

    static count = async (tableName) => {
        let result = 0;
        let dbConnection = await this.openDbConnection();
        try {
            let tx = await dbConnection.transaction(tableName);
            let store = await tx.store;
            result = await store.count();
        } catch (error) {
            console.warn('Error in Store.count():');
            console.warn(error);
            dbConnection.close();
        }
        return result;
    }

    static getAll = async (tableName, optionalIncludeDeleted) => {
        if (!tableName) return [];
        optionalIncludeDeleted = optionalIncludeDeleted || false;
        let resultArray = [];
        let dbConnection = await this.openDbConnection();
        try {
            let tx = await dbConnection.transaction(tableName);
            let store = await tx.store;
            let cursor = await store.openCursor();
            while (cursor) {
                let recJson = cursor.value;
                let includeRecord = true;
                if ((!optionalIncludeDeleted) && (this.IS_DELETED_FIELD in recJson) && (recJson[this.IS_DELETED_FIELD])) {
                    includeRecord = false; // 
                }
                if (includeRecord) resultArray.push(recJson);
                cursor = await cursor.continue();
            }
            await tx.done;
        } catch (error) {
            console.warn('Error in Store.getAll():');
            console.warn(error);
            dbConnection.close();
        }
        return resultArray;
    }

    static getByLocalId = async (tableName, indexValue) => {
        let resultRecord = null
        let dbConnection = await this.openDbConnection();
        try {
            const tx = await dbConnection.transaction(tableName);
            resultRecord = await tx.store.get(indexValue);
            await tx.done;
        } catch (error) {
            console.warn('Error in Store.getByLocalId():');
            console.warn(error);
            dbConnection.close();
        }
        return resultRecord;
    }

    static getAllDataMatchingIndex = async (tableName, indexFieldName, indexValue, optionalIncludeDeleted) => {
        optionalIncludeDeleted = optionalIncludeDeleted || false;
        var resultArray = [];
        let dbConnection = await this.openDbConnection();
        try {
            const tx = await dbConnection.transaction(tableName);
            let store = await tx.store;
            const index = await store.index(indexFieldName);
            for await (const cursor of index.iterate(indexValue)) {
                let recJson = cursor.value;
                let includeRecord = true;
                if ((!optionalIncludeDeleted) && (this.IS_DELETED_FIELD in recJson) && (recJson[this.IS_DELETED_FIELD])) {
                    includeRecord = false; // 
                }
                if (includeRecord) resultArray.push(recJson);
                //await cursor.continue();
            }
            await tx.done;
        } catch (error) {
            console.warn('Error in Store.getAllDataMatchingIndex():');
            console.warn(error);
            dbConnection.close();
        }
        return resultArray;
    }

    static getMostRecentBySetLids = async (tableName, indexFieldName, setLidsArray, setLidFieldName, maxRecords, optionalIncludeDeleted) => {
        optionalIncludeDeleted = optionalIncludeDeleted || false;
        var resultArray = [];
        let dbConnection = await this.openDbConnection();
        try {
            let count = 0;
            const tx = await dbConnection.transaction(tableName);
            let store = await tx.store;
            const index = await store.index(indexFieldName);
            let isSetLidsArrayEmpty = ((typeof setLidsArray === 'undefined') || (setLidsArray.length == 0)) ? true : false;
            let cursor = await index.openCursor(null, 'prev');
            while (cursor) {
                let recJson = cursor.value;
                let includeRecord = true;
                if ((!optionalIncludeDeleted) && (this.IS_DELETED_FIELD in recJson) && (recJson[this.IS_DELETED_FIELD])) {
                    includeRecord = false; // 
                }
                if (includeRecord) {
                    let setLid = recJson[setLidFieldName];
                    if ((isSetLidsArrayEmpty) || setLidsArray.includes(setLid)) {
                        resultArray.push(recJson);
                        count++;
                        if (count == maxRecords) break; // we have enough!
                    }
                }
                await cursor.continue();
            }
            await tx.done;
        } catch (error) {
            console.warn('Error in Store.getMostRecentBySetLids():');
            console.warn(error);
            dbConnection.close();
        }
        return resultArray;
    }

    static getAllTableDataSinceDate = async (tableName, dtFieldName, dtFrom) => {
        let timeFrom = dtFrom.getTime();
        let resultArray = [];
        let dbConnection = await this.openDbConnection();
        let lastLid = -1;
        try {
            let tx = await dbConnection.transaction(tableName);
            let store = await tx.store;
            let cursor = await store.openCursor();
            while (cursor) {
                let recJson = cursor.value;
                if (dtFieldName in recJson) {
                    let recTime = new Date(recJson[dtFieldName]).getTime();
                    if (recTime >= timeFrom) {
                        if (lastLid != recJson.lid) { // hack to avoid the last rec being duplicated!
                            resultArray.push(recJson);
                        }
                        lastLid = recJson.lid;
                    }
                }
                await cursor.continue();
            }
            await tx.done;
        } catch (error) {
            console.warn('Error in Store.getAllTableDataSinceDate():');
            console.warn(error);
            dbConnection.close();
        }
        return resultArray;
    }

    static getTableDataPaginated = async (tableName, pageLength, optionalPageNumber) => {
        let resultArray = [];
        let dbConnection = await this.openDbConnection();
        //let lastLid = -1;
        let requiredPageNumber = optionalPageNumber || 1;
        let index = 0;
        let currentPageNumber = 1;

        try {
            let tx = await dbConnection.transaction(tableName);
            let store = await tx.store;
            let cursor = await store.openCursor();
            while (cursor) {
                let recJson = cursor.value;
                /*if (dtFieldName in recJson) {
                    let recTime = new Date(recJson[dtFieldName]).getTime();
                    if (recTime >= timeFrom) {
                        if (lastLid != recJson.lid) { // hack to avoid the last rec being duplicated!
                            resultArray.push(recJson);
                        }
                        lastLid = recJson.lid;
                    }
                }*/
                if (currentPageNumber == requiredPageNumber) {
                    resultArray.push(recJson);
                }
                index++;
                if (index % pageLength == 0) currentPageNumber++;

                await cursor.continue();
            }
            await tx.done;
        } catch (error) {
            console.warn('Error in Store.getTableDataPaginated():');
            console.warn(error);
            dbConnection.close();
        }
        return resultArray;
    }

    static getFirstViaIndex = async (tableName, indexedFieldName, indexedFieldValue) => {
        var resultRec = null;
        let dbConnection = await this.openDbConnection();
        try {
            const tx = await dbConnection.transaction(tableName);
            let store = await tx.store;
            const index = await store.index(indexedFieldName);
            for await (const cursor of index.iterate(indexedFieldValue)) {
                let rec = cursor.value;
                if (rec[indexedFieldName] == indexedFieldValue) {
                    resultRec = cursor.value;
                    break;
                }
            }
            await tx.done;
        } catch (error) {
            console.warn('Error in Store.getFirstViaIndex():');
            console.warn(error);
            dbConnection.close();
        }
        return resultRec;
    }

    static getItem = async (tableName, localId) => {
        let resultItem = null;
        let dbConnection = await this.openDbConnection();
        try {
            const tx = await dbConnection.transaction(tableName);
            resultItem = await tx.store.get(localId);
            await tx.done;
        } catch (error) {
            console.warn('Error in Store.getItem():');
            console.warn(error);
            dbConnection.close();
        }
        return resultItem;
    }

    static insertRecord = async (tableName, recJson, optionalSkipDtUpdate) => {
        optionalSkipDtUpdate = optionalSkipDtUpdate || false;
        let tableSchema = this.schema[tableName];
        if (!optionalSkipDtUpdate) recJson[tableSchema.LocalFieldNames.LastUpdated] = new Date();
        if (tableSchema.LocalFieldNames.RemoteId in recJson === false) {
            recJson[tableSchema.LocalFieldNames.RemoteId] = this.NULL_REMOTE_ID;
        }
        let dbConnection = await this.openDbConnection();
        const tx = await dbConnection.transaction(tableName, this.WRITABLE_MODE);
        let localId = await tx.store.add(recJson);
        recJson[tableSchema.LocalFieldNames.LocalId] = localId;
        await tx.done;
        dbConnection.close();
        return localId;
    }

    static insertMultipleRecords = async (tableName, arrayOfRecJson, optionalSkipDtUpdate) => {
        optionalSkipDtUpdate = optionalSkipDtUpdate || false;
        let tableSchema = this.schema[tableName];
        let dbConnection = await this.openDbConnection();
        const tx = await dbConnection.transaction(tableName, this.WRITABLE_MODE);
        for (let i = 0; i < arrayOfRecJson.length; i++) {
            let recJson = arrayOfRecJson[i];
            if (!optionalSkipDtUpdate) recJson[tableSchema.LocalFieldNames.LastUpdated] = new Date();
            if (tableSchema.LocalFieldNames.RemoteId in recJson === false) {
                recJson[tableSchema.LocalFieldNames.RemoteId] = this.NULL_REMOTE_ID;
            }
            let localId = await tx.store.add(recJson);
            recJson[tableSchema.LocalFieldNames.LocalId] = localId;
        }
        await tx.done;
        dbConnection.close();
        return arrayOfRecJson;
    }

    static updateRecord = async (tableName, recJson, optionalSkipDtUpdate) => {
        optionalSkipDtUpdate = optionalSkipDtUpdate || false;
        let tableSchema = this.schema[tableName];
        //let lid = recJson[tableSchema.LocalFieldNames.LocalId];
        if (!optionalSkipDtUpdate) recJson[tableSchema.LocalFieldNames.LastUpdated] = new Date();
        if (tableSchema.LocalFieldNames.RemoteId in recJson === false) {
            recJson[tableSchema.LocalFieldNames.RemoteId] = this.NULL_REMOTE_ID;
        }
        let dbConnection = await this.openDbConnection();
        const tx = await dbConnection.transaction(tableName, this.WRITABLE_MODE);
        //await dbConnection.put(tableName, recJson);
        await tx.store.put(recJson);
        await tx.done;
        dbConnection.close();
    }

    static deleteRecord = async (tableName, localId) => {
        let dbConnection = await this.openDbConnection();
        const tx = await dbConnection.transaction(tableName, this.WRITABLE_MODE);
        await tx.store.delete(localId);
        await tx.done;
        dbConnection.close();
    }

    // demo of how to list all databases
    async listDatabasesToConsole(dbConn) {
        indexedDB.databases().then(r => {
            console.log(r);
        });
    }
};