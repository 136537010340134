import PageBase from './PageBase.js';
import UserAccounts from '../panels/Admin/UserAccounts.js';
import RealmAdmin from '../panels/Admin/RealmAdmin.js';
import PersonAdmin from '../panels/Admin/PersonAdmin.js';
import RolePersonRealmAdmin from '../panels/Admin/RolePersonRealmAdmin.js';
import GroupAdmin from '../panels/Admin/GroupAdmin.js';
import GroupMemberAdmin from '../panels/Admin/GroupMemberAdmin.js';

import CategoryAdmin from '../panels/Admin/CategoryAdmin.js';
import SkillAdmin from '../panels/Admin/SkillAdmin.js';
import RatingAdmin from '../panels/Admin/RatingAdmin.js';
import SkillRatingAdmin from '../panels/Admin/SkillRatingAdmin.js';

import PersonRatingAdmin from '../panels/Admin/PersonRatingAdmin.js';
import PersonReviewAdmin from '../panels/Admin/PersonReviewAdmin.js';

import VerifyEmail from '../panels/User/VerifyEmail/VerifyEmail.js';

// Added 19/11/2023

import MeasureAdmin from '../panels/Admin/MeasureAdmin.js';
import PersonMeasurementAdmin from '../panels/Admin/PersonMeasurementAdmin.js';
import SessionAdmin from '../panels/Admin/SessionAdmin.js';
import SessionAttendeeAdmin from '../panels/Admin/SessionAttendeeAdmin.js';

// Added 27/11/2023
import InviteAdmin from '../panels/Admin/InviteAdmin.js'


export default class SystemsAdminPage extends PageBase {
    Title = 'System';
    #SECTIONS = {
        "LOADING": "AdminLoading",
    };

    #PANELS = [
        {"divId": "AdminUserAccounts", 'es6Class': UserAccounts},
        {"divId": "AdminRealms", 'es6Class': RealmAdmin},
        {"divId": "AdminPeople", 'es6Class': PersonAdmin},
        {"divId": "AdminRolePersonRealm", 'es6Class': RolePersonRealmAdmin},
        {"divId": "AdminCoachGroup", 'es6Class': GroupAdmin},
        {"divId": "AdminCoachGroupMembers", 'es6Class': GroupMemberAdmin},
        {"divId": "AdminCategories", 'es6Class': CategoryAdmin},
        {"divId": "AdminSkills", 'es6Class': SkillAdmin},
        {"divId": "AdminRatings", 'es6Class': RatingAdmin},
        {"divId": "AdminSkillRatings", 'es6Class': SkillRatingAdmin},
        {"divId": "AdminPersonRatings", 'es6Class': PersonRatingAdmin},
        {"divId": "AdminPersonReviews", 'es6Class': PersonReviewAdmin},

        {"divId": "AdminMeasures" , 'es6Class': MeasureAdmin},
        {"divId": "AdminPersonMeasurements" , 'es6Class': PersonMeasurementAdmin},
        {"divId": "Sessions" , 'es6Class': SessionAdmin},
        {"divId": "SessionMembers", 'es6Class': SessionAttendeeAdmin},
        {"divId": "InviteAdmis", 'es6Class': InviteAdmin},
    ];

    #whoAmI = null;

    constructor(whoAmI) {
        super();
        this.#whoAmI = whoAmI;
    }

    async Render() {
        await this.Init();
    }

    Init = async () => {
        let sectionsDivsArray = this.#sectionsAsDivArray(this.#SECTIONS.LOADING, this.#PANELS);
        let sectionsHtml = this.MakePageSectionsHtml(sectionsDivsArray);
        $('main#Content').html(sectionsHtml);
        await this.#populateSections(this.#whoAmI);
        this.#configureClickHandlers();
    }

    #configureClickHandlers() {
    }

    #sectionsAsDivArray(verifyEmailSectionDivId, panelsArrayOfJson) {
        let sectionDivs = [];
        sectionDivs.push(verifyEmailSectionDivId);
        for (let i=0;i<panelsArrayOfJson.length;i++) {
            sectionDivs.push(panelsArrayOfJson[i].divId)
        }
        return sectionDivs;
    }
    async #populateSections(whoJson) {
        if (whoJson && !whoJson.isVerified) {
            let uiVerify = new VerifyEmail('#' + this.#SECTIONS[0], whoJson);
            await uiVerify.Render();
        }
        for (let i=0;i<this.#PANELS.length;i++) {
            let panelJson = this.#PANELS[i];
            let divId = panelJson.divId;
            let es6Class = panelJson.es6Class;
            let uiAdminPanel = new es6Class('#'+divId, whoJson);
            await uiAdminPanel.Render();
        }
    }

}