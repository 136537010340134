import PageBase from './PageBase.js';
import Hero from  '../panels/Home/Hero/Hero.js'
import Privacy from  '../panels/Home/Privacy/Privacy.js'

export default class PrivacyPage extends PageBase {
    Title = 'Privacy';
    #SECTIONS = ["HeroSection", "PrivacySection"];

    async Render() {
        await this.Init();
    }
    
    Init = async () => {
        let sectionsHtml = this.MakePageSectionsHtml(this.#SECTIONS);
        $('main#Content').html(sectionsHtml);
        await this.#populateSections();
        this.#configureClickHandlers();
    }
    
    // internals

    #configureClickHandlers ()  { // dont use arrow function def otherwise 'this' may not be valid
    }

    async #populateSections () {
        let uiHero = new Hero('#'+this.#SECTIONS[0]);
        await uiHero.Render();
        let uiPrivacy = new Privacy('#'+this.#SECTIONS[1]);
        await uiPrivacy.Render();
    }
}
