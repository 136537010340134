export default {

    "Email": "email",
    "Name": "name",
    "UserId": "id",
    "PersonNumber": "PNumber",
    "RefreshToken": "RToken",
    "ClientId": "Cid",
    "RealmId": "RealmId",
    "RealmName": "RealmName",
    "Currency": "Currency",
    "Language": "Lang",
    "LastSyncDt": "LastSyncDt",
    "IsVerified": "IsVerified",

    "SystemsAdmin": "SystemsAdmin",
    "Admin": "Admin",
    "SubscriptionType": "SubscriptionType",
    "SubscriptionDt": "SubscriptionDt",
    "Role": "Role",
    "Status": "Status"


};