import PageBase from './PageBase.js';
import Hero from  '../panels/Home/Hero/Hero.js'
import Terms from  '../panels/Home/Terms/Terms.js'

export default class TermsPage extends PageBase {
    Title = 'Terms';
    #SECTIONS = ["HeroSection", "PrivacySection"];

    async Render() {
        await this.Init();
    }
    
    Init = async () => {
        let sectionsHtml = this.MakePageSectionsHtml(this.#SECTIONS);
        $('main#Content').html(sectionsHtml);
        await this.#populateSections();
        this.#configureClickHandlers();
    }
    
    // internals

    #configureClickHandlers ()  { // dont use arrow function def otherwise 'this' may not be valid
    }

    async #populateSections () {
        let uiHero = new Hero('#'+this.#SECTIONS[0]);
        await uiHero.Render();
        let uiTerms = new Terms('#'+this.#SECTIONS[1]);
        await uiTerms.Render();
    }
}
