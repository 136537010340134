//import {FieldLoading} from './wcs/FieldLoading/FieldLoading.js';
import Store from './data/local/Shared/Store.js';
import { PageHandler } from './ui/PageHandler.js';

export class WebApp {
    static Init = async () => {
        await Store.Initialise();
        await PageHandler.Init();
        await this.#configureClickHandlers()
    }

    // internals

    static #configureClickHandlers() {
        $(document).ready(function() { // handle back button gracefully to rerender page
            $(window).on("popstate", function (e) {
                location.reload();
            });
        });
    }
}