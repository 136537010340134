// keys are the name of the JSON returned by the API, values are the names stored in local config
export default {
    "userId": "userId",
    "clientKey": "clientKey",
    "refreshToken": "refreshToken",
    "email": "email",
    "nickname": "nickname",
    "name": "name",
    "personNumber": "personNumber",
    "realmId": "realmId",
    "realmName": "realmName",
    "currency": "currency",
    "language": "language",
    "lastSyncDt": "lastSyncDt",
    "isVerified": "isVerified",
    "isLearner": "isLearner",
    "isSystemsAdmin": "isSystemsAdmin",
    "isAdmin": "isAdmin",
    "isFinance": "isFinance",
    "isSeniorCoach": "isSeniorCoach",
    "isCoach": "isCoach",
    "personId": "personId",
    "parentPersonId": "parentPersonId",
    "defaultRealmId": "defaultRealmId",
    "firstname": "firstname",
    "lastname": "lastname",
    "defaultRealmName": "defaultRealmName",
    "otherRealmsRoles": "otherRealsmRoles",
    "subscriptionType": "subscriptionType",
    "subscriptionEnd": "subscriptionEnd",
    "role": "role",
    "status": "status",
    "key": "key"
};