import SessionAttendee from '../../../data/api/SessionAttendee.js';
import AdminPanelBase from './Shared/AdminPanelBase.js';

export default class SessionAttendeeAdmin extends AdminPanelBase {
    
    constructor(where, whatJson) {
        let buttons = '';
        whatJson.sectionTitle = SessionAttendee.GetTableSummary().displayNamePlural;
        whatJson.sectionDescription = SessionAttendee.GetTableSummary().description;
        super(where, whatJson, SessionAttendee, buttons);
    }

}