import Tokeniser from "../utils/Tokeniser";
//import ButtonLoading from '../wcs/ButtonLoading/ButtonLoading.js';
//import FieldLoading from '../wcs/FieldLoading/FieldLoading.js';

export default class UiBase {
    // every class that inherits from UiBase increments this in constructor, meaning each gets a unique counter (within the page) for the Id
    static idCounter = 0;

    #FLASH_CLASS = 'flashMessages';
    #FLASH_CLOSE_DELAY = 5000; // 5 seconds
    #flashMsgTimeout = null;

    #ID_PREFIX = 'UiBase_';
    Id = 'UiBase_0'; // this gets set in the constructor so each instance has a unique Id

    #whereSelectorString = ''; // HTML DOM query selector for jquery
    #whatJson = {}; // attributes to replace tokens with

    // html template we will do tokenisation on and use as the main UI: 
    //   {this.foo} gets replaced with this object public attribs, e.g. foo in this example
    //   Use {this.Id} for the outer element, e.g. <div id="{this.Id}">Content</div>
    //   {what.bar} is replaced with whatJson.bar
    //   whatJsonMappings.bar is used if there is any mapping needed for .bar attribute
    #howTemplateHtml = '';

    // optional what attributes that are mapped, from perhaps an int to text, in an enum like fashion
    // see Template above and /js/ui/panels/User/AccountDetails/Mappings.js for example
    #whatJsonMappings = {};


    constructor(whereSelectorString, whatJson, howTemplateHtml, optionalWhatJsonMappings) {
        this.Id = this.#ID_PREFIX + UiBase.idCounter;
        UiBase.idCounter++;
        this.#whereSelectorString = whereSelectorString || '';
        this.#whatJson = whatJson || {};
        this.#whatJsonMappings = optionalWhatJsonMappings || {};
        this.#howTemplateHtml = howTemplateHtml || '';
        //console.log('UiBase ID: ' + this.Id)
    }

    async ProcessButtonAsyncAction(jqButton, asyncActionFunc) {
        $('section.' + this.#FLASH_CLASS).hide();
        jqButton.prop('disabled', true);
        jqButton.addClass('disabledButton');
        let buttonText = jqButton.html();
        jqButton.html('<button-loading></button-loading>');
        try {
            await asyncActionFunc(jqButton);
        } catch (err) {
            console.warn('Error occurred on button click: ', err);
        }
        jqButton.prop('disabled', false);
        jqButton.removeClass('disabledButton');
        jqButton.html(buttonText);
    }

    ShowFlashMessage(msg, optionalTimeoutDelayMs) {
        let timeoutDelayMs = optionalTimeoutDelayMs || this.#FLASH_CLOSE_DELAY;
        if (this.#flashMsgTimeout) {
            clearTimeout(this.#flashMsgTimeout);
            this.#flashMsgTimeout = null;
        }
        $('section.' + this.#FLASH_CLASS).html(msg);
        $('section.' + this.#FLASH_CLASS).fadeIn();
        if (timeoutDelayMs != 0) {
            this.#flashMsgTimeout = setTimeout(() => {
                $('section.' + this.#FLASH_CLASS).slideUp();
            }, timeoutDelayMs);
        }
    }

    Render() {
        let html = this.#generateHtml(this.#howTemplateHtml, this.#whatJson, this.#whatJsonMappings);
        $(this.#whereSelectorString).html(html);
        $(this.#whereSelectorString).show();
    }

    GenerateHtml() {
        return this.#generateHtml(this.#howTemplateHtml, this.#whatJson, this.#whatJsonMappings);
    }

    Hide() {
        $(this.#whereSelectorString).hide();
    }

    SlideUp() {
        $(this.#whereSelectorString).slideUp();
    }

    #generateHtml(template, what, mappings) {
        return Tokeniser.DoTokenReplacement(this, template, what, mappings);
    }

}