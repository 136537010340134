import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import Auth from '../../../../utils/Auth.js';

export default class AccountPwdReset extends UiBase {
    #USER_ID = '_userId';
    #ORIGINAL_PWD_INPUT = '_existingpwd';
    #NEW_PWD_INPUT = '_newpwd';
    #VERIFY_PWD_INPUT = '_newpwd2';
    #RESET_PWD_BUTTON = '_resetpwdbtn';

    constructor(where, whatJson) {
        super(where, whatJson, TemplateHtml);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        //console.log('--------->'+'#' + this.Id + this.#RESET_PWD_BUTTON)
        let jqButton = $('#' + this.Id + this.#RESET_PWD_BUTTON);
        jqButton.off('click');
        jqButton.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#resetPwd);
        });
    }

    #resetPwd = async() => {
        let userId = $('#' + this.Id + this.#USER_ID).val();
        let originalPwd = $('#' + this.Id + this.#ORIGINAL_PWD_INPUT).val();
        let newPwd = $('#' + this.Id + this.#NEW_PWD_INPUT).val();
        let verifyPwd = $('#' + this.Id + this.#VERIFY_PWD_INPUT).val();
        //console.log('resetPwd');
        let resultsJson = await Auth.ResetPassword(userId, originalPwd, newPwd, verifyPwd);
        if (('err' in resultsJson) && (resultsJson.err)) {
            this.ShowFlashMessage(resultsJson.err);
        }
    }
}