import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import Auth from '../../../../utils/Auth.js';

export default class DeleteAccount extends UiBase {
    #USER_ID = '_userId';
    #DELETE_ACCOUNT_BUTTON = '_DeleteAccount';

    constructor(where, whatJson) {
        super(where, whatJson, TemplateHtml);
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        let jqButton = $('#' + this.Id + this.#DELETE_ACCOUNT_BUTTON);
        jqButton.off('click');
        jqButton.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#deleteAccount);
        });
    }

    #deleteAccount = async() => {
        let userId = $('#' + this.Id + this.#USER_ID).val();
        console.log('deleteAccount');
        //let resultsJson = await Auth.Logout(userId);
        if (('err' in resultsJson) && (resultsJson.err)) {
            this.ShowFlashMessage(resultsJson.err);
        }
    }

}