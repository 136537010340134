import JourneyBase from "../JourneyBase.js";

export default class ShowAdminPage extends JourneyBase {
    static Name = 'ShowAdminPage';
    static Description = 'Organisation';
    static Icon = '<i class="fa-solid fa-cubes-stacked"></i>';

    static async Start() {
        let pageTarget = '/#admin';
        window.location.href = pageTarget;
    }
}