import Category from '../../../data/api/Category.js';
import AdminPanelBase from './Shared/AdminPanelBase.js';

export default class CategoryAdmin extends AdminPanelBase {
    
    constructor(where, whatJson) {
        let buttons = '';
        whatJson.sectionTitle = Category.GetTableSummary().displayNamePlural;
        whatJson.sectionDescription = Category.GetTableSummary().description;
        super(where, whatJson, Category, buttons);
    }

}