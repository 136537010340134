import Header from './panels/Home/Header/Header.js'
import Footer from './panels/Home/Footer/Footer.js'
import { MainNavigation } from './buttons/mainNavigation/MainNavigation.js'; // TODO: rename/relocate (inside header) as Hamburger menu
import CurrentUser from '../utils/CurrentUser.js';
import Auth from '../utils/Auth.js';
import HomePage from './webPages/HomePage.js';
import AboutPage from './webPages/AboutPage.js';
import PrivacyPage from './webPages/PrivacyPage.js';
import TermsPage from './webPages/TermsPage.js';
import CookiesPage from './webPages/CookiesPage.js';
import ContactPage from './webPages/ContactPage.js';
import LoginPage from './webPages/LoginPage.js';
import UserHomePage from './webPages/UserHomePage.js';
import MyAccountPage from './webPages/MyAccountPage.js';
import SystemsAdminPage from './webPages/SystemsAdminPage.js';
import SystemsAdminTestPage from './webPages/SystemsAdminTestPage.js';
import AdminPage from './webPages/AdminPage.js';
import JourneyManager from './journeys/JourneyManager.js';
import UnknownPage from './webPages/UnknownPage.js';
import Url from '../utils/Url.js';

export class PageHandler {
    static #mainNav = null;
    static #pageController = null;
    static #PAGE_CONTENT = 'main#Content';
    static #PAGE_LOADING_DIV = '#PageLoading';
    //static #PAGE_NAV_CLASS = "pageNav";
    static #PUBLIC_ROUTES = ['', '#', '#home', '#about', '#privacy', '#terms', '#contact', '#cookies'];
    static #userDetails = { "email": null, "name": null };

    static Init = async (optionalPageName) => {
        $(this.#PAGE_LOADING_DIV).show();
        $(this.#PAGE_CONTENT).html('');
        $(this.#PAGE_CONTENT).hide();
        this.#mainNav = new MainNavigation(0);
        let uiHeader = new Header();
        uiHeader.Render();
        let uiFooter = new Footer();
        uiFooter.Render();
        let pageName = this.#getPageNameLowerCase(optionalPageName);
        this.#mainNav.Init(pageName);
        await this.#renderPage(pageName);
    }

    // internals

    static #getPageNameLowerCase = (optionalPageName) => {
        let pageName = '';
        pageName = optionalPageName || $(location).attr('pathname');
        if (pageName.startsWith('/')) pageName = pageName.substring(1).toLowerCase();
        if ((pageName.length == 0) && (location.hash)) pageName = location.hash;
        if (pageName.indexOf('?') != -1) {
            pageName = pageName.substring(0, pageName.indexOf('?')); // remove query string
        }
        console.log('pageName: ', pageName);
        return pageName;
    }

    static #renderPage = async (pageName) => {
        if (this.#PUBLIC_ROUTES.includes(pageName)) {
            this.#pageController = await this.#createPublicPageController(pageName);
        } else if (pageName == '#googleauthcb') {
            await this.#handleGoogleAuthCallback(); // this will reload page at /#user
        } else {
            let isFirstTimeRun = await CurrentUser.IsFirstTimeRun();
            isFirstTimeRun = false; // force this to false for now until a new user journey is defined
            if (isFirstTimeRun) {
                console.log('First time run, start new user journey here')
            } else { // protected routes
                this.#pageController = await this.#createProtectedPageController(pageName);
            }
        }
        if (!this.#pageController) { // error, unrecognised page requested
            this.#pageController = new UnknownPage();
        } else {
            document.title = document.title + ' - ' + this.#pageController.Title;
        }
        if ((this.#pageController) && ('SetSuccessCallback' in this.#pageController)) {
            this.#pageController.SetSuccessCallback(this.Init);
        }
        if ((this.#pageController) && ('Init' in this.#pageController)) { // render content for page
            $(this.#PAGE_CONTENT).html('');
            await this.#pageController.Init();
        }
        this.#clickHandlers();
        if (pageName != '#googleauthcb') { // for google auth callback, we want to show the field loading/busy div while we wait for the redirect
            $(this.#PAGE_LOADING_DIV).hide();
            $(this.#PAGE_CONTENT).show();
        }
    }

    static #createPublicPageController = async (pageName) => {
        let pageController = null;
        switch (pageName) {
            case '':
            case '#':
            case '#home':
                pageController = new HomePage();
                break;
            case '#about':
                pageController = new AboutPage();
                break;
            case '#privacy':
                pageController = new PrivacyPage();
                break;
            case '#terms':
                pageController = new TermsPage();
                break;
            case '#contact':
                pageController = new ContactPage();
                break;
            case '#cookies':
                pageController = new CookiesPage();
                break;
        }
        return pageController;
    }

    static #createProtectedPageController = async (pageName) => {
        let pageController = null;
        this.#userDetails = await CurrentUser.GetUserDetails();
        let isSystemsAdmin = false;
        if (this.#userDetails && ('isSystemsAdmin' in this.#userDetails) && (this.#userDetails.isSystemsAdmin)) {
            isSystemsAdmin = true;
        }
        let isAdmin = false;
        if (this.#userDetails && ('isAdmin' in this.#userDetails) && (this.#userDetails.isAdmin)) {
            isAdmin = true;
        }
        switch (pageName) {
            case '#myaccount':
                pageController = new MyAccountPage(this.#userDetails);
                break;
            case '#user':
            case '#userhome': // used by google auth to redirect to after success
                if (!this.#userDetails.email) {
                    pageController = new LoginPage();
                } else {
                    pageController = new UserHomePage(this.#userDetails);
                }
                break;
            case '#admin':
                if (!this.#userDetails.email) {
                    pageController = new LoginPage();
                } else {
                    if (isAdmin) {
                        pageController = new AdminPage(this.#userDetails);
                    } else {
                        pageController = new MyAccountPage(this.#userDetails);
                    }
                }
                break;
            case '#systemsadmin':
                if (!this.#userDetails.email) {
                    pageController = new LoginPage();
                } else {
                    if (isSystemsAdmin) {
                        pageController = new SystemsAdminPage(this.#userDetails);
                    } else {
                        pageController = new MyAccountPage(this.#userDetails);
                    }
                }
                break; case '#journey':
                await this.#startJourney();
                break;
            case '#systemsadmintest':
                if (!this.#userDetails.email) {
                    pageController = new LoginPage();
                } else {
                    if (isSystemsAdmin) {
                        pageController = new SystemsAdminTestPage(this.#userDetails);
                    } else {
                        pageController = new MyAccountPage(this.#userDetails);
                    }
                }
                break; case '#journey':
                await this.#startJourney();
                break;
            default:
                console.log('No page handler defined for: ' + pageName);
        }
        return pageController;
    }

    static #clickHandlers() {
       /* let self = this;
        $('.' + this.#PAGE_NAV_CLASS).off('click')
        $('.' + this.#PAGE_NAV_CLASS).on('click', function (e) {
            e.preventDefault();
            let pageTarget = $(this).attr('href');
            window.history.pushState({ href: pageTarget }, '', pageTarget);
            self.Init(pageTarget);
            return true;
        });*/
    }

    static async #handleGoogleAuthCallback() {
        // Expected query string from successful auth
        // http://localhost:4002/#googleauthcb?
        // clientKey=ae31c80088a0ce09f294d03ad449291d1472610632cde69484227f17d2be3024
        // refreshToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJuaWNrbmFtZSI6IkdsZW4gQmlya2JlY2siLCJpYXQiOjE3MDEwNzY4MzgsImV4cCI6MTcwMjI4NjQzOH0.mabcgROXfsNWJp0-JUcSsRZHb-mZxLZSuYd4tGNCQtA
        // id=1&
        // email=glenbirkbeck%40gmail.com
        // name=Glen+Birkbeck
        let queryString = window.location.href.substring(window.location.href.indexOf('?') + 1);
        let queryParams = new URLSearchParams(queryString);
        console.log('queryParams: ', queryParams);
        let userId = Url.GetParameter('id');
        let clientKey = Url.GetParameter('clientKey');
        let refreshToken = Url.GetParameter('refreshToken');
        let email = Url.GetParameter('email');
        let name = Url.GetParameter('name');
        if (name.indexOf(' ') != -1) name = name.split(' ')[0];
        if (name.indexOf('+') != -1) name = name.split('+')[0];
        await Auth.GoogleAuthComplete(clientKey, refreshToken, userId, email, name);
    }

    static async #startJourney(journeyName) {
        let jm = new JourneyManager();
        console.log(jm.AvailableJourneys('Coach'));

    }
}
