import PageBase from './PageBase.js';
import AccountDetails from '../panels/User/AccountDetails/AccountDetails.js';
import PersonDetails from '../panels/User/PersonDetails/PersonDetails.js';
import AccountPwdReset from '../panels/User/AccountPwdReset/AccountPwdReset.js';
import Logout from '../panels/User/Logout/Logout.js';
import DeleteAccount from '../panels/User/DeleteAccount/DeleteAccount.js';
import VerifyEmail from '../panels/User/VerifyEmail/VerifyEmail.js';
import AdminLink from '../panels/User/DELETE_AdminLink/AdminLink.js';

export default class MyAccountPage extends PageBase {
    Title = 'Account';
    #SECTIONS = ["VerifyEmailSection",  "UserAccountDetailsSection", "PersonDetails", "PasswordResetSection", "LogoutSection", "DeleteAccountSection"];
    #whoAmI = null;
    #successCallback = null;

    constructor(whoAmI) {
        super();
        this.#whoAmI = whoAmI;
    }

    async Render() {
        await this.Init();
    }

    SetSuccessCallback(successCallback) {
        this.#successCallback = successCallback;
    }

    Init = async () => {
        let sectionsHtml = this.MakePageSectionsHtml(this.#SECTIONS);
        $('main#Content').html(sectionsHtml);
        await this.#populateSections();
        this.#configureClickHandlers();
    }

    // internals

    #configureClickHandlers() {
    }

    async #populateSections() {
        if (this.#whoAmI && !this.#whoAmI.isVerified) {
            let uiVerify = new VerifyEmail('#' + this.#SECTIONS[0], this.#whoAmI);
            await uiVerify.Render();
        }
        let uiAd = new AccountDetails('#' + this.#SECTIONS[1], this.#whoAmI);
        await uiAd.Render();

        let panelPerson = new PersonDetails('#' + this.#SECTIONS[2], this.#whoAmI);
        await panelPerson.Render();

        let uiPwdReset = new AccountPwdReset('#' + this.#SECTIONS[3], this.#whoAmI);
        await uiPwdReset.Render();
        let uiLogout = new Logout('#' + this.#SECTIONS[4], this.#whoAmI);
        uiLogout.SetSuccessCallback(this.#successCallback);
        await uiLogout.Render();
        let uiDeleteAccount = new DeleteAccount('#' + this.#SECTIONS[5], this.#whoAmI);
        await uiDeleteAccount.Render();
    }
}