import PageBase from './PageBase.js';
import Login from '../panels/User/Login/Login.js';
import GoogleLogin from '../panels/User/GoogleLogin/GoogleLogin.js';
import Register from '../panels/User/Register/Register.js';

export default class LoginPage extends PageBase {
    Title = 'Login';
    #SECTIONS = ["LoginSection", "GoogleLoginSection", "RegisterSection"];
    #successCallback = null;

    async Render() {
        await this.Init();
    }
    
    Init = async () => {
        let sectionsHtml = this.MakePageSectionsHtml(this.#SECTIONS);
        $('main#Content').html(sectionsHtml);
        await this.#populateSections();
        this.#configureClickHandlers();
    }

    SetSuccessCallback(successCallback) {
        this.#successCallback = successCallback;
    }
    
    // internals

    #configureClickHandlers ()  { // dont use arrow function def otherwise 'this' may not be valid
    }

    async #populateSections () {
        let loginPanel = new Login('#'+this.#SECTIONS[0]);
        loginPanel.SetSuccessCallback(this.#successCallback);
        await loginPanel.Render();
        let gLoginPanel = new GoogleLogin('#'+this.#SECTIONS[1]);
        gLoginPanel.SetSuccessCallback(this.#successCallback);
        await gLoginPanel.Render();
        let registerPanel = new Register('#'+this.#SECTIONS[2]);
        registerPanel.SetSuccessCallback(this.#successCallback);
        await registerPanel.Render();
    }
}
