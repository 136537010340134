import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';

export default class AdminLink extends UiBase {
    #CLOSE_BTN_ID = 'CloseAdminPanel';

    constructor(where, whatJson) {
        where = where || 'main#Content';
        super(where, whatJson, TemplateHtml);
    }

    async Render() {
        super.Render();
        this.#configureEventHandlers();
    }

    #configureEventHandlers() {
        let self = this;
        let jqButton = $('#' + this.#CLOSE_BTN_ID);
        jqButton.off('click');
        jqButton.on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), self.#close);
        });
    }

    #close = () => {
        this.SlideUp();
    }

}