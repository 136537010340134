import UiBase from '../../../UiBase.js';
import TemplateHtml from './Template.html';
import Quotes from '../../../../utils/Quotes.js';

const TIME_DELAY = 5000;
const imageClasses = ['headlineImage1', 'headlineImage2', 'headlineImage3', 'headlineImage4', 'headlineImage5', 'headlineImage6', 'headlineImage7', 'headlineImage8', 'headlineImage9'];

function* getImage() { // continuous iterator
    let i = 0
    while ( i < imageClasses.length) {
        yield imageClasses[i];
        //console.log('Yielded: ' + imageClasses[i]);
        if (i === imageClasses.length - 1) {
            i = 0 //this will reset the loop to 0 with i++
        } else {
            i++
        }
    }
}
function getRandomInt (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
const imageGenerator = getImage();

export default class Hero extends UiBase {
    #MAIN_IMG_DIV = '_headline';
    #remainingQuotes = [];
    #isAnimated = true;
    #animationTimer = null;

    constructor(where, whatJson) {
        where = where || 'main';
        super(where, whatJson, TemplateHtml);
        this.#remainingQuotes = [].concat(Quotes, ["At the top of your game"]); // generate a copy of quotes
    }

    Render() {
        super.Render();
        this.#configureEventHandlers();
        this.#showNextImage();
    }

    ShowNextImage = () => { // used for restarting the animation from the click handler
        this.#showNextImage();
    }

    #configureEventHandlers() {
        let self = this;
        let jqH1 = $('#' + this.Id + this.#MAIN_IMG_DIV + '>h1');
        jqH1.off('click');
        jqH1.on('click', function() {
            self.#isAnimated = !self.#isAnimated;
            if (self.#isAnimated) {
                self.ShowNextImage();
            } else if (self.#animationTimer) { 
                clearTimeout(self.#animationTimer);
            }
            console.log('isAnimated: ' + self.#isAnimated);
        });
    }

    #showNextImage = () => {
        const imageClassToAdd = imageGenerator.next().value;
        if (!imageClassToAdd) return;
        // change hero image
        let jqMainImageDiv = $('#' + this.Id + this.#MAIN_IMG_DIV)
        for (let image of imageClasses) {
            jqMainImageDiv.removeClass(image);
        }
        jqMainImageDiv.addClass(imageClassToAdd);
        // change hero title text
        let quoteIndex = getRandomInt(1, this.#remainingQuotes.length) -1;
        let quote = this.#remainingQuotes[quoteIndex];
        let jqH1 = $('#' + this.Id + this.#MAIN_IMG_DIV + '>h1');
        jqH1.html(quote);
        // set timer going again if the hero div is visible
        if (this.#isAnimated  && (jqMainImageDiv.css("visibility") === "visible")) {
            this.#animationTimer  = setTimeout(this.#showNextImage, TIME_DELAY);
        } else {
            this.#animationTimer = null;
        }
    };


}