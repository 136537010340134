import UserJourneys from  './User/Index.js';
import CoachJourneys from "./Coach/Index.js";
import AdminJourneys from './Admin/Index.js';
import SystemsAdminJourneys from './SystemsAdmin/Index.js';
import CurrentUser from "../../utils/CurrentUser.js";


export default class JourneyManager {
    static #journeysJson = null;

    static AllAvailableJourneys = async () => {
        let resultsJson = {};
        let userJson = await CurrentUser.GetUserDetails();
        if (userJson.isSystemsAdmin) {
            resultsJson['System'] = SystemsAdminJourneys;
        }
        if (userJson.isAdmin) {
            resultsJson['Admin'] = AdminJourneys;
        }
        if (userJson.isCoach) {
            resultsJson['Coach'] = CoachJourneys;
        }
        resultsJson['You'] = UserJourneys;
        this.#journeysJson = resultsJson;
        return resultsJson;
    }

    /*AvailableJourneys = (role) => {
        if (!role || (role in allJourneys === false)) return [];
        else {
            let journeysArray = [];
            let journeys = allJourneys[role];
            let journeyNames = Object.keys(journeys);
            for (let i = 0; i < journeyNames.length; i++) {
                let journeyName = journeyNames[i];
                let journeyClass = journeys[journeyName];
                let journeyDescription = journeyClass.Description;
                let journeyDetails = {
                  "Name": journeyName,
                  "Class": journeyClass,
                  "Description": journeyDescription,
                  "Icon": journeyClass.Icon || '<i class="fa-solid fa-circle-question"></i>'
                }
                journeysArray.push(journeyDetails);
            }
            return journeysArray;
        }
    }*/

    static  StartJourney = async (category, journeyName) => {
        /*if (this.currentJourney) {
            console.warn("A journey is already in progress. Finish it before starting a new one.");
            return;
        }
        this.currentJourney = journeyName;*/
        console.log(`Started user journey: ${category} - ${journeyName}`);
        let journeysJson = this.#journeysJson;
        journeysJson[category][journeyName].Start()
    }

    static  EndJourney = () => {
        if (!this.currentJourney) {
            console.warn("No journey in progress. Cannot end a journey.");
            return;
        }
        console.log(`Ended user journey: ${this.currentJourney}`);
        this.currentJourney = null;
    }

    static  PerformAction = async (actionName) => {
        if (!this.currentJourney) {
            console.warn("No journey in progress. Cannot perform an action.");
            return;
        }
        console.log(`Performed action "${actionName}" in user journey: ${this.currentJourney}`);
    }
}
