import axios from 'axios';
import CurrentUser from './CurrentUser.js';

export default class Api {
    static EXPECTED_REPONSE_CODES = {
        "OK": 200,
        "CREATED": 201,
        "ACCEPTED": 202
    };
    static UNAUTHORISED_CODE = 401;
    static UNAUTHORISED_STATUS_TEXT = 'Unauthorized';
    static BAD_REQUEST = '"ERR_BAD_REQUEST"';

    static async Get(uri, data) {
        let responseJson = await Api.#get(uri, data);
        //console.log('Api.Get() responseJson: ', responseJson);
        if (responseJson && (this.isRequestUnauthorised(responseJson))) {
            let hasRefreshedTokens = await this.UseRefreshToken();
            if (hasRefreshedTokens) {
                responseJson = await Api.#get(uri, data);
            }
        }
        return responseJson;
    }

    static async Post(uri, data) {
        let responseJson = await Api.#post(uri, data);
        //console.log('Api.Post() responseJson: ', responseJson);
        if (responseJson && (this.isRequestUnauthorised(responseJson))) {
            let hasRefreshedTokens = await this.UseRefreshToken();
            if (hasRefreshedTokens) {
                responseJson = await Api.#post(uri, data);
            }
        }
        return responseJson;
    }

    static isRequestUnauthorised(responseJson) {
        let isUnauthorised = false;
        if ((typeof responseJson !== 'undefined') && (responseJson.status == this.UNAUTHORISED_CODE) && (responseJson.statusText == this.UNAUTHORISED_STATUS_TEXT)) {
            isUnauthorised = true;
        }
        return isUnauthorised;
    }

    static async UseRefreshToken(optionalData) {
        let gotNewAccessToken = false;
        let userDetails = await CurrentUser.GetUserDetails();
        console.log('Api.UseRefreshToken() userDetails: ', userDetails);
        if ((optionalData) || (userDetails.clientKey && userDetails.refreshToken)) {
            const uri = '/auth/other/useRefreshToken';
            let data = optionalData || {
                "clientKey": userDetails.clientKey,
                "refreshToken": userDetails.refreshToken,
                "email": userDetails.email,
                //"name": userDetails.name
            };
            let responseJson = await Api.#post(uri, data);
            console.log('--> Api.UseRefreshToken() ', responseJson);
            console.log('--> Api.UseRefreshToken() responseJson.data typeof ', typeof responseJson.data);

            if (responseJson.err) {
                console.warn('Error in Api.UseRefreshToken(): ', responseJson.err);
            } else if ((responseJson.status) && (responseJson.status != this.EXPECTED_REPONSE_CODES.OK)) {
                console.warn('Error in Api.UseRefreshToken(): ', responseJson);
            } else {
                if (typeof responseJson.data == 'string') {
                    responseJson.data = JSON.parse(responseJson.data);
                }
                await CurrentUser.SetUserDetails(responseJson.data);
                gotNewAccessToken = true; // if we are lucky!
            }
        }
        return gotNewAccessToken;
    }

    static Headers() {
        //let token = this.ACCESS_TOKEN;
        let headers = null;
        /*if (token) {
            headers = {
                headers: {
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${token}`,
                },
            };
            }*/
        return headers;
    }

    static JsonResponseHandler = (response, expectedCode) => {
        let jsonResults = { err: null, data: null };
        if (!response) {
            jsonResults.data = '';
            jsonResults.err = 'Failed no data returned!';
        } else if (response.status == expectedCode) {
            if ('data' in response)
                jsonResults.data = response.data;
            else
                jsonResults.err = 'Apparently successful but no data returned!';
        } else {
            if ('data' in response)
                jsonResults.err = response.data;
            else
                jsonResults.err = 'Response status code: ' + response.status + '. Failed but no data returned!';
        }
        return jsonResults;
    }

    // Internals

    static async #get(uri, data) {
        data = data || {};
        let queryString = '';
        let keys = Object.keys(data);
        if (keys.length != 0) {
            keys.forEach((key) => {
                if (queryString.length == 0) queryString += '?';
                else queryString += '&';
                queryString += key;
                queryString += '=';
                queryString += data[key];
            });
        }
        let headers = this.Headers();
        try {
            let response = await axios.get(uri + queryString, headers);
            //console.log('Api.#get() success: ' + uri, response);
            return response;
        } catch (err) {
            //console.log('Api.#get() error: ', err);
            if (err?.isAxiosError) {
                err = err.response; //all the info here
            } else {
                err = err; // it is not an AxiosError
            }
            return err;
        }
    }

    static async #post(uri, data) {
        data = data || {};
        let headers = this.Headers();
        try {
            const response = await axios.post(uri, data, headers);
            //console.log('Api.#post() success: ' + uri, response);
            return response;
        } catch (err) {
            //console.log('Api.#post() error: ', err);
            if (err?.isAxiosError) {
                err = err.response; //all the info here
            } else {
                err = err; // it is not an AxiosError
            }
            return err;
        }
    }
}