import JourneyBase from "../JourneyBase.js";

export default class ShowSystemsTestPage extends JourneyBase {
    static Name = 'ShowSystemsTestPage';
    static Description = 'Test';
    static Icon = '<i class="fa-solid fa-house-medical"></i>';

    static async Start() {
        let pageTarget = '/#systemsadmintest';
        window.location.href = pageTarget;
    }
}