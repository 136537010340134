import UiBase from '../UiBase.js';

export default class PageBase extends UiBase {
    //#SECTIONS = []; // subclass defines array oif strings that will be div ids for each section

    constructor(whereDivId) {
        whereDivId = whereDivId || 'main#Content';
        super(whereDivId);
    }

    MakePageSectionsHtml(sectionsArray) {
        let sectionsHtml = '';
        for (let i=0;i<sectionsArray.length;i++) {
            let sectionDivId = sectionsArray[i];
            sectionsHtml += '<section id=' + sectionDivId + '></section>';
        }
        return sectionsHtml;
    }

    //async #populateSections () {} // subclass defines this    

}